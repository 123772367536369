import React, { useState, useEffect } from "react";

import { useSessionStorage, pushNotification } from "@telosalliance/ui-core-framework";
import { Routing } from "./component/Routing";

import jwt_decode from "jwt-decode";
import InviteLanding from "./pages/InviteLanding";
import { Login } from "./pages/Login";
import Dashboard from "./pages/Dashboard";
import Users from "./pages/Users";
// from "./pages/UserInvites"; // Invite page is "on-hold"
import Settings from "./pages/Settings";

import LockIcon from "@material-ui/icons/LockOutlined";
import PeopleAltIcon from '@material-ui/icons/PeopleAlt';
import DashboardIcon from '@material-ui/icons/Dashboard';
import SettingsIcon from '@material-ui/icons/Settings';

import 'devextreme/dist/css/dx.common.css';
import 'devextreme/dist/css/dx.dark.css';
import "./app.css";


const App = () => {

    const [isLoggedIn, setIsLoggedIn] = useSessionStorage(
        "tls-beacon-loggedin",
        false
    );

    const [bakedToken, setBakedToken] = useSessionStorage(
        "tls-beacon-usertoken",
        ""
    );

    const [authError, setAuthError] = useState();

    const [userTimeZone, setUserTimeZone] = useState("UTC");

    const loginTitle = isLoggedIn ? "Log Out" : "Log In";

    const [hideSideMenu, setHideSideMenu] = useState(false);
    const [hideTopMenu, setHideTopMenu] = useState(false);


    const [menuTree, setMenuTree] = useState([{
        path: "/login",
        name: "Log In",
        component: Login,
        props: { isLoggedIn, setIsLoggedIn, bakedToken, setBakedToken, setAuthError, userTimeZone, setUserTimeZone, hideSideMenu, setHideSideMenu, hideTopMenu, setHideTopMenu },
        isProtected: false,
        icon: LockIcon,
    }]);


    const adminRoutes = [
        {
            path: "/dashboard",
            name: "Dashboard",
            component: Dashboard,
            props: { isLoggedIn, setIsLoggedIn, bakedToken, setBakedToken, setAuthError, userTimeZone, setUserTimeZone, hideSideMenu, setHideSideMenu, hideTopMenu, setHideTopMenu },
            isProtected: true,
            icon: DashboardIcon,
        },
        {
            path: "/users",
            name: "Users",
            component: Users,
            props: { isLoggedIn, setIsLoggedIn, bakedToken, setBakedToken, setAuthError, userTimeZone, setUserTimeZone, hideSideMenu, setHideSideMenu, hideTopMenu, setHideTopMenu },
            isProtected: true,
            icon: PeopleAltIcon,
        },
        {
            path: "/settings",
            name: "Settings",
            component: Settings,
            props: { isLoggedIn, setIsLoggedIn, bakedToken, setBakedToken, setAuthError, userTimeZone, setUserTimeZone, hideSideMenu, setHideSideMenu, hideTopMenu, setHideTopMenu },
            isProtected: true,
            icon: SettingsIcon,
        },
        //{
        //    path: "/login",
        //    name: loginTitle,
        //    component: Login,
        //    props: { isLoggedIn, setIsLoggedIn, bakedToken, setBakedToken, setAuthError, userTimeZone, setUserTimeZone, hideSideMenu, setHideSideMenu, hideTopMenu, setHideTopMenu },
        //    isProtected: false,
        //    icon: LockIcon,
        //},
    ];

    const userRoutes = [
        {
            path: "/dashboard",
            name: "Dashboard",
            component: Dashboard,
            props: { isLoggedIn, setIsLoggedIn, bakedToken, setBakedToken, setAuthError, userTimeZone, setUserTimeZone, hideSideMenu, setHideSideMenu, hideTopMenu, setHideTopMenu },
            isProtected: true,
            icon: DashboardIcon,
        },
        //{
        //    path: "/login",
        //    name: loginTitle,
        //    component: Login,
        //    props: { isLoggedIn, setIsLoggedIn, bakedToken, setBakedToken, setAuthError, userTimeZone, setUserTimeZone, hideSideMenu, setHideSideMenu, hideTopMenu, setHideTopMenu },
        //    isProtected: false,
        //    icon: LockIcon,
        //},

    ];

    const apiRoutes = [
        {
            path: "/login",
            name: loginTitle,
            component: Login,
            props: { isLoggedIn, setIsLoggedIn, bakedToken, setBakedToken, setAuthError, userTimeZone, setUserTimeZone, hideSideMenu, setHideSideMenu, hideTopMenu, setHideTopMenu },
            isProtected: false,
            icon: LockIcon,
        },
    ];

    const doNavigate = (url) => {
        //window.location.replace(url);
        window.location.href = url;
    }

    useEffect(() => {
        if (bakedToken) {

            /*TODO*/ // add try/catch
            const bakedJson = JSON.parse(atob(bakedToken)); //decode base64

            // console.log("userJson",userJson);

            const decodedToken = jwt_decode(bakedJson.token);

            //console.log("decodedToken",decodedToken);

            if (decodedToken) {

                var showLogoutMenu = true;

                var getVipLogin = sessionStorage.getItem("tls-beacon-vip-loggedin");

                if (getVipLogin) { showLogoutMenu = false;}

                //console.log("getVipLogin", getVipLogin, "showLogoutMenu", showLogoutMenu);

                var logoutMenuItem = {
                    path: "/login",
                    name: "Log Out",
                    component: Login,
                    props: { isLoggedIn, setIsLoggedIn, bakedToken, setBakedToken, setAuthError, userTimeZone, setUserTimeZone, hideSideMenu, setHideSideMenu, hideTopMenu, setHideTopMenu },
                    isProtected: false,
                    icon: LockIcon,
                };

                switch (decodedToken.role) {
                    case "Admin":
                        if (showLogoutMenu) {
                            adminRoutes.push(logoutMenuItem);
                        }
                        setMenuTree(adminRoutes);
                        break;

                    case "User":
                        if (showLogoutMenu) {
                            adminRoutes.push(logoutMenuItem);
                        }
                        setMenuTree(userRoutes);
                        break;

                    default:
                        setMenuTree(apiRoutes);
                        break;
                }
            }
        } else {
            setMenuTree(apiRoutes);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [bakedToken]);


    useEffect(() => {

        if (authError && authError.response) {

            // console.log("*** authError", authError);
            //  console.log("*** status 1:", authError.response.status);

            // 401 = Unauthorized, 403 = Forbidden
            if (authError.response.status === 401 || authError.response.status === 403) {

                pushNotification("ERROR - Your not authorized, logging out... (" + authError.response.status + ")");

                sessionStorage.removeItem('tls-beacon-loggedin');
                sessionStorage.removeItem('tls-beacon-usertoken');
                sessionStorage.removeItem('tls-beacon-vip-loggedin');

                setMenuTree(apiRoutes);

                setTimeout(() => {
                    doNavigate("./login");
                }, 100);


            } else {

                console.log("status 2: ", authError.message);
                pushNotification("ERROR - " + authError.response.status);
            }
            /*else {
              if(only403 === undefined || only403 === false) {
                pushNotification("ERROR (" + title + "): " + err.message);
                console.log("ERROR (" + title + "): ", err.message);
              }
            }*/
        } else {

            if (authError === "noToken") {

                pushNotification("ERROR - Your not authorized, logging out... (no token)");

                sessionStorage.removeItem('tls-beacon-loggedin');
                sessionStorage.removeItem('tls-beacon-usertoken');
                sessionStorage.removeItem('tls-beacon-vip-loggedin');

                setMenuTree(apiRoutes);

                setTimeout(() => {
                    doNavigate("./login/");
                }, 100);

            }
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [authError]);



    // console.log(" window.location.search", window.location.search);

    // do we want to show the INVITE PAGE or the pages?
    if (window.location.search.startsWith("?c=") ||
        window.location.search.startsWith("?lite=") ||
        window.location.search.startsWith("?iqs=")) {

        //Show invite page
        //console.log("App - InviteLanding, isLoggedIn:", isLoggedIn);
        return (
            <>
                <Routing
                    isLoggedIn={isLoggedIn}
                    home={{
                        component: InviteLanding,
                        props: { isLoggedIn, setIsLoggedIn, bakedToken, setBakedToken, authError, setAuthError, hideSideMenu, setHideSideMenu, hideTopMenu, setHideTopMenu },
                    }}
                    routes={apiRoutes}
                >
                </Routing>

            </>
        );

    } else {

        // Show login and pages
        //console.log("App - Login, isLoggedIn:", isLoggedIn);
        return (
            <>

                <Routing
                    isLoggedIn={isLoggedIn}
                    home={{
                        component: Login,
                        props: { isLoggedIn, setIsLoggedIn, bakedToken, setBakedToken, authError, setAuthError, hideSideMenu, setHideSideMenu, hideTopMenu, setHideTopMenu },
                    }}
                    routes={menuTree}
                >
                </Routing>

            </>
        );
    };

}

export default App;
