import React, { Component } from 'react';
import axios from "axios";
import sha1 from 'crypto-js/sha1';
import Base64 from 'crypto-js/enc-base64';
import { Panel, Button, Input, IconButton} from "@telosalliance/ui-core";
import UpdateIcon from '@material-ui/icons/Update';
import { formatDistanceToNow } from 'date-fns'
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';

import WebRtcLiveNet from './WebRtcLiveNet';
import WebRtcAxiaIQs from './WebRtcAxiaIQs';
import WebRtcLiteClient from './WebRtcLiteClient';

class InviteLanding extends Component {
    constructor(props) {
        super(props);
        this.state = { 
            tlsTokenValid:false,
            tlsToken:'',
            inviteID:'',
            pageTitle:'',
            validInvite:false,
            isInvite:false,
            isBlockedInvite:false,
            isExpiredInvite:false,
            isCommingUpInvite:false,
            startDate: 0,
            endDate:0,
            blockedMessage:'',
            isLocalGUI:false,
            isPasswordValid:0,
            validStatus:0,
            inviteCode:'',
            inviteData:{ inviteName:'?', inviteSession:'?' },
            hasStated:false,
            usePassword:true,
            showPasswordInput:false,
            isRefreshed:false,
            isAutorized:false,
            passwordChecker:0,
            isLoaded:false,
            isRedirecting: false,
            isSimpleClient: false,
            simpleClientMode:0,
            cookieHash: ''
      

         }
    }

    getUrlParams() {
        var vars = {};
        window.location.href.replace(/[?&]+([^=&]+)=([^&]*)/gi, function (
          m,
          key,
          value
        ) {
          vars[key] = value;
        });
        return vars;
    }

    getToken() {
        // get "bakedToken" from session in browser
        if(sessionStorage.getItem('tls-beacon-usertoken')) {
          const bakedToken = sessionStorage.getItem('tls-beacon-usertoken').replace("__STRING__", "");
          const bakedJson = JSON.parse(atob(bakedToken)); //decode base64
  
          // use the callback in setState, this so we really have the values in the state before we use them.
          this.setState({ tlsTokenValid: true, tlsToken: bakedJson.token, isLoaded:true }, () => {
            // load stuff
            this.initPage();
          });
  
        } else {
          this.setState({ tlsTokenValid: false, tlsToken: "" , isLoaded:true}, () => {
            // load stuff
              this.initPage();
          });
        }
      } 
  
    componentDidMount() {

      this.props.setHideSideMenu(true); // call to useEffect on App.js that tells Routing component to hide navigation menu...

      const _invite = this.getUrlParams();

      //#### DON'T FORGET TO ADD NEW url params to "App.js" (row ~258) ####

      if (_invite.c !== undefined) {
        //console.log("Load Beacon client");
        this.getToken(); //check if we have a token, then init the page.
      } else if(_invite.lite !== undefined) {

          console.log("Load Simple client");
          this.props.setHideTopMenu(true); 
          this.setState({isSimpleClient:true, simpleClientMode: 1});

      } else if(_invite.iqs !== undefined) {

        console.log("Load Simple iQs client");
        this.props.setHideTopMenu(true); 
        this.setState({isSimpleClient:true, simpleClientMode: 2});

      } else {

         console.log("No parameterd for invite found..."); 
         // this.props.history.push('./login/'); // not needed anymore, 'App.js' has a "selector on row ~258"
      }
    }

    renderLiteClient(urlParams) {

      if(this.state.isSimpleClient) {
        const _invite = this.getUrlParams();
        if(this.state.simpleClientMode===1) {
          return ( <WebRtcLiteClient mode={1} urlParams={_invite.lite} />)
        } else if(this.state.simpleClientMode===2) {
          return ( <WebRtcLiteClient  mode={2} urlParams={_invite} />)
        }
          
      } else {
        return null;
      }
    }

  
    initPage() {

        const _invite = this.getUrlParams();
  
        if(_invite.c) {  
  
            // if invite url is not complete or tampered with the base64 string is not correct and "atob" can not decode it, so trow error.
            try {
               
              const invite = window.atob(_invite.c); 
              const invJson = JSON.parse(invite);
  
              let _title = invJson.title;
              if(_title === "") {_title="Telos LiveNet";}
  
              // override the CSS "productLogo" and set content 
              var styleSheet = document.createElement("style")
              styleSheet.innerText = '.productLogo:before{content:"' + _title + '" !important;}';
              document.head.appendChild(styleSheet)
              document.title = _title;
  
              this.setState({isInvite:true, inviteID: invJson.code, pageTitle: _title, inviteCode: invJson.code}, 
                () => {
                    this.fetchInvite()
                })

            } catch {
              console.log("Invite URL was tampered")
              this.setState({isInvite:false,validInvite:false}); 
            }
  
            
        
        } else if(_invite.c === undefined) {  

          this.setState({inviteID:'', validInvite:false});

        } else {
          this.setState({inviteID:'', validInvite:false});
        }
    }

    
    
    fetchInvite(){

        const self = this;
  
        axios.get('./api/checkInvite?code='+ this.state.inviteCode)
        .then(function (response) {
          self.getInviteType(response.data);
        })
        .catch(function (error) {
          console.log("getInvite ERR", error);
          self.setState({validInvite:false,isInvite:false})
        })
        .then(function () {
          // always executed
        });  
    }

    reloadInvite = (invcode) => {

      this.setState({isInvite:true, inviteCode: invcode, isLocalGUI:false}, 
        () => {
            this.fetchInvite()
        })

    }

    getInviteType(data){

      if(data && data.tp === 1) {

        // create a Base64 'Hash' used for login cookie
        const cookieStr =  data.inviteGUID + data.inviteSession;
        const cookieHash = window.btoa(cookieStr);

        this.setState({inviteData: data, cookieHash: cookieHash});    

        if(data.isBlocked){
            this.setState({isBlockedInvite:true, blockedMessage: data.blockedMessage});
            return;
        }

        // check if we allow no/zero password
        if(data.invitePwdHash === "gt-6#S8ZA9$*ZELeS^jQ&RYAYcgu!*xL3rrRuS47") { //Hash used for no/zero password
          
          this.setState({usePassword:false, showPasswordInput:false, isAutorized:true}, () =>{
            this.parseInvite(data);
          });
        
        } else {

          this.checkLoginCookie();

          //if not, lets check if were local using token or we ask for password
          if(this.state.isAutorized) {
              this.parseInvite(data);
          } else {
            if(this.state.tlsTokenValid || this.state.usePassword === false){
              this.setState({showPasswordInput:false, isAutorized:true}, () =>{
                this.parseInvite(data);
              });
            } else {
              this.setState({showPasswordInput:true});
            }
          }

        }
        

      } else {
        // NOT an valid invite!
        console.log("ERR - getInviteType: NOT an valid invite!");
        this.setState({ isInvite:false })
      }

    }

    parseInvite(data){

      //console.log("inviteData", data);
        
      /* Valid invite */
      if(data.tp === 1) {

          //invite has expired
          if(data.validStatus === 0)
          {   
              this.setState({isRedirecting: false, isExpiredInvite:true, startDate: data.startDateSec, endDate: data.endDateSec, showPasswordInput:false});
              return;
          }

          //invite not started yet
          if(data.validStatus === 2)
          {
              this.setState({isRedirecting: false, isCommingUpInvite:true, startDate: data.startDateSec, endDate: data.endDateSec, showPasswordInput:false});     
              return;
          }


          if(data.validStatus === 1) {

            // check type of invite
            // Redirect URL (Infinity VIP)
            if(data.inviteMode === 3) {

                const redirJson = {
                    guid: data.guid,
                    start: data.startDateSec,
                    end: data.endDateSec,
                    valid: data.isValid,
                    blocked: data.isBlocked
                }  
                
                const redirJsonStr = JSON.stringify(redirJson);
                const redirJsonStrBase64 = new Buffer.from(redirJsonStr).toString('base64');
                this.setState({isRedirecting:true, validStatus: data.validStatus, startDate: data.startDateSec, endDate: data.endDateSec, isLocalGUI:false, showPasswordInput:false}, () => {

                  // wait for the setState to finish, then wait a second, then redirect...
                  setTimeout(() => {
                    window.location = this.state.inviteData.inviteServer + "tl=" + redirJsonStrBase64;
                  }, 1000)

                })

            } else {
              
              // use internal client. 1 = LiveNet, 2 = AxiaIiQs, 4= Telos VXs
              this.setState({isRedirecting: false, validStatus: data.validStatus, startDate: data.startDateSec, endDate: data.endDateSec, isLocalGUI:true, showPasswordInput:false})
            }

        }

      }
      
    }

    renderNotAnInvite(){

      if(this.state.isSimpleClient) {
        return null;
      }

        if(this.state.isInvite === false && this.state.isLoaded === true) {
            return ( <React.Fragment>
                <center style={{ marginTop:"50px" }}>
                  <Panel style={{ minWidth: 360, maxWidth: 800, padding:"20px" }}>
                  <h1>This Invite does not exist or is deleted</h1>
                  </Panel>
                </center>
                </React.Fragment>
            );
        }
    }  

    renderBlockedInvite() {

      if(this.state.isSimpleClient) {
        return null;
      }

        if(this.state.isBlockedInvite){
          return ( <React.Fragment>
            <center style={{ marginTop:"50px" }}>
              <Panel style={{ minWidth: 360, maxWidth: 800, padding:"20px" }}>
              <h1>Blocked</h1>
              <br/>
              <h2>{this.state.inviteData.inviteName}, this invite has been Blocked</h2>
              <h4>{this.state.blockedMessage}</h4>
              </Panel>
            </center>
            </React.Fragment>);
        }
    }


    renderRedirectingInvite() {

      if(this.state.isSimpleClient) {
        return null;
      }

      if(this.state.isRedirecting){
        return ( <React.Fragment>
          <center style={{ marginTop:"50px" }}>
            <Panel style={{ minWidth: 360, maxWidth: 800, padding:"20px" }}>
            <h1>Redirecting</h1>
            <br/>
            <h4>Please wait...</h4>
            </Panel>
          </center>
          </React.Fragment>);
      }
  }


    startInvite = () => {

     // console.log("Redirecting or showing content...");  

       this.setState({hasStated: true}, () => {

        setTimeout(() => {
            this.fetchInvite(); 
        }, 1000)

       });     
    }

    renderNotStartedYetInvite(){

      if(this.state.isSimpleClient) {
        return null;
      }

        if(this.state.isCommingUpInvite === true && this.state.hasStated === false) {

            let timeLeft = "will start in " + formatDistanceToNow(new Date(this.state.startDate * 1000), {includeSeconds: true});

            if(new Date(Date.now()) > new Date(this.state.startDate * 1000)) {

                this.startInvite();
                timeLeft = "connecting, please wait...";
            }

            return ( <React.Fragment>
                <center style={{ marginTop:"50px" }}>
                  <Panel style={{ minWidth: 360, maxWidth: 800, padding:"20px" }}>
                    <h2>{this.state.inviteData.inviteName}, this invite has not yet started</h2>
                    <div style={{marginTop:'10px'}}><i>{ timeLeft}</i></div>

                    <div style={{marginTop:'15px'}}>
                        <IconButton onClick={()=>{ 
                          this.setState({isRefreshed:true}, () => { this.fetchInvite() })}
                          } icon={UpdateIcon} tooltip="Refresh" />
                    </div>

                  </Panel>
                </center>
                </React.Fragment>
            );
        }
    }  

    renderExpiredInvite() {

      if(this.state.isSimpleClient) {
        return null;
      }

        if(this.state.isExpiredInvite){
          return ( <React.Fragment>
            <center style={{ marginTop:"50px" }}>
              <Panel style={{ minWidth: 360, maxWidth: 800, padding:"20px" }}>
              <h2>{this.state.inviteData.inviteName}, this invite has expired!</h2>
              </Panel>
            </center>
            </React.Fragment>);
        }
      }

    renderLocalGUI() {
      
      if(this.state.isSimpleClient) {
        return null;
      }

      if(this.state.isLocalGUI){ 

        switch(this.state.inviteData.inviteMode) {
          case 0:
            return;

          case 1:
            return <WebRtcLiveNet session={this.state.inviteData} inviteId={this.state.inviteCode} refreshCallback={this.reloadInvite} />

          case 2:
            return <WebRtcAxiaIQs session={this.state.inviteData} />

          default:
            return ( <React.Fragment>
              <center style={{ marginTop:"50px" }}>
                <Panel style={{ minWidth: 360, maxWidth: 800, padding:"20px" }}>
                <h2>Welcome {this.state.inviteData.inviteName}</h2>
               
                <br/>
                GUI not available for session: {this.state.inviteData.inviteSession}
                <br/>
                <br/>
                <div className="uic-modal-btnGroup">
                  <Button onClick={()=>{ this.setState({isLocalGUI: false} ) }}>Close this</Button>
                </div>
                </Panel>
              </center>
              </React.Fragment>); 
        }

      }

    }

    checkPassword = (value) => {

        const pwd = value.trim();
        const pwdHash = Base64.stringify(sha1(pwd));
  
        if(pwd.length > 0){
          this.setState({isAutorized:false, passwordChecker:2}) 
        }
        //console.log('this.state.inviteData',this.state.inviteData)
        if(this.state.inviteData.invitePwdHash === pwdHash /*|| pwd === "AAAA"*/) {
          
          // set cookie for more convenient experience, not needing the password the second time used...
          this.setCookie('beacon_' + this.state.inviteData.inviteGUID, this.state.cookieHash)

          // valid password
          this.setState({passwordChecker:1}, () => {
            // short deley get get a visual effect that the password is processed...
            setTimeout(() => {
                this.setState({isAutorized:true, showPasswordInput:false} , () => {
                  this.parseInvite(this.state.inviteData);
                }) 
            }, 500);
          }) 
  
        } else {
          this.setState({isAutorized:false, passwordChecker:2})   
        }
  
    };

    checkLoginCookie = () => {
      var beconinvCookie = this.getCookie('beacon_' + this.state.inviteData.inviteGUID);

      if(beconinvCookie && beconinvCookie === this.state.cookieHash) {
        // valid password
        this.setState({passwordChecker:1}, () => {
          this.setState({isAutorized:true, showPasswordInput:false} , () => {
            this.parseInvite(this.state.inviteData);
          })
        }) 

        return;
      }
    }

    setCookie (cname, cvalue, exdays) {
      if (!exdays) { exdays = 365 }
      var d = new Date()
      d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000)
      var expires = 'expires=' + d.toUTCString()
      document.cookie = cname + '=' + cvalue + ';' + expires + ';path=/'
    }
  
    getCookie (cname) {
      var name = cname + '='
      var decodedCookie = decodeURIComponent(document.cookie)
      var ca = decodedCookie.split(';')
      for (var i = 0; i < ca.length; i++) {
        var c = ca[i]
        while (c.charAt(0) === ' ') {
          c = c.substring(1)
        }
        if (c.indexOf(name) === 0) {
          return c.substring(name.length, c.length)
        }
      }
      return null
    }
  
    

    renderUsePassword() {

      if(this.state.isSimpleClient) {
        return null;
      }


        if(this.state.showPasswordInput && this.state.isAutorized === false && this.state.isRefreshed === false) {
          return ( 
            <React.Fragment>
            <center style={{ marginTop:"50px" }}>
            <Panel style={{ minWidth: 360, maxWidth: 800, padding:"20px"}}>
            <h2>Welcome, {this.state.inviteData.inviteName}</h2>
            <br />
            Login to this session by entering the password that you got in the invite email.
            <br /><br />
            <div>
            <Input type="text" 
                  id="txtAuth"
                 
                  onChange={this.checkPassword} 
                  name="inviteName"
                  maxLength="64" 
                  autoComplete="off" /> 
             

              {this.state.passwordChecker === 2 && ( 
                <ErrorOutlineIcon style={{color:"red", marginTop:"1px", marginLeft:"10px", position: "absolute"}} />
              )}

              {this.state.passwordChecker === 1 && ( 
                <CheckCircleOutlineIcon style={{color:"green", marginTop:"1px", marginLeft:"10px", position: "absolute"}} />
              )}
            </div>
            </Panel></center>  
            </React.Fragment>
            );
        }
    }

    render() { 
        return ( 
            <React.Fragment>
                {this.renderLiteClient()}
                {this.renderRedirectingInvite()}
                {this.renderNotAnInvite()}
                {this.renderBlockedInvite()}
                {this.renderExpiredInvite()}
                {this.renderNotStartedYetInvite()}
                {this.renderLocalGUI()}
                {this.renderUsePassword()}
            </React.Fragment>
         );
    }
}
 
export default InviteLanding;