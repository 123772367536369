import React, { Component } from "react";

import {
  pushNotification,
  confirm,
  prompt,
  alert,
} from "@telosalliance/ui-core-framework";
import {
  Row,
  Panel,
  Table,
  Toggle,
  Select,
  Button,
  IconButton,
  Modal,
  InfoTooltip,
} from "@telosalliance/ui-core";
import Checkbox from "@material-ui/core/Checkbox";
import Divider from "@material-ui/core/Divider";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import AddBoxIcon from "@material-ui/icons/AddBox";
import WarningIcon from "@material-ui/icons/Warning";
import SaveIcon from "@material-ui/icons/Save";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import Grid from "@material-ui/core/Grid";

import { AddCircleOutline } from "@material-ui/icons";

import sha1 from "crypto-js/sha1";
import Base64 from "crypto-js/enc-base64";

import axios from "axios";

import TimeZoneSelect from "./TimeZoneSelect";
import { formatDistanceToNow } from "date-fns";

class Users extends Component {
  constructor(props) {
    super(props);

    this.state = {
      userItems: [],
      curUserItem: {},
      curUserRole:"",
      curItemStart: 0,
      curItemPage: 25,
      totalItemsCount: 1,
      isEditMode: false,
      editModalOpen: false,
      agentGroupTitel: "",
      agentGroupGUID: "",
      allowAddUser: true,
      addUserModalOpen: false,
      editUserPwd: "",
      editUserPwdConfirm: "",
      editUserPwdChangeMatch: false,
      userGUID: "",
      editUserHash: "",
      editUserPwdStrong: false,
      newUser: {
        firstName: "",
        lastName: "",
        email: "",
        pwd: "",
        pwdCheck: "",
        role: "User",
        timeZone: "UTC",
      },
      newUserPwd: "",
      newUserPwdConfirm: "",
      newUserPwdChangeMatch: true,
      newUserHash: "",
      newUserPwdStrong: false,
      userRole: "",
      userTimeZone: "",
      isFirstLogin: false,
      numAdmins:0
    };

    // using this method since state is async, and can't be read direct after set.
    this.tokenHeader = "";

    this.userRoles = [
      { id: "User", label: "User", disabled: false },
      { id: "Admin", label: "Admin", disabled: false },
      // {id: "SuperAdmin", label: "Super Admin", disabled:true}
    ];
  }

  getToken() {
    // get "bakedToken" from session (session gets populated via login, remove __STRING__ which is added by Daren's "useSessionStorage")
    if (sessionStorage.getItem("tls-beacon-usertoken")) {
      const bakedToken = sessionStorage
        .getItem("tls-beacon-usertoken")
        .replace("__STRING__", "");
      const bakedJson = JSON.parse(atob(bakedToken)); //decode base64

      //console.log("bakedJson",bakedJson)

      this.tokenHeader = {
        headers: {
          Authorization: "Bearer " + bakedJson.token,
          "Content-Type": "application/json",
        },
      };

      // use the callback in setState, this so we really have the values in the state before we use them.
      this.setState(
        {
          agentGroupGUID: bakedJson.agentGroupGuid,
          userGUID: bakedJson.userGuid,
          userRole: bakedJson.role,
          userTimeZone: bakedJson.timeZone,
        },
        () => {
          // load local stuff
          this.loadAllUsers(0);
        }
      );
    } else {
      this.props.setAuthError("noToken");
    }
  }

  componentDidMount() {
    this.getToken();

    if (this.props.isFirstLogin) {
      this.setState({ isFirstLogin: true });
      this.handleEditBtn(this.props.uid);
    }
  }

  redirectToLogin = () => {

    const fldv = document.getElementById("firstLoginDiv");
    if(fldv) {
      fldv.hidden = true;
    }
    

    pushNotification("Redirecting to Login...", {
      variant: "success",
    });

    sessionStorage.removeItem("tls-beacon-loggedin");
    sessionStorage.removeItem("tls-beacon-usertoken");

    window.location.replace("./login");
  };

  loadAllUsers(startpos) {
    axios
      .get(
        "./api/clientusers?start=" +
          startpos +
          "&page=" +
          this.state.curItemPage,
        this.tokenHeader
      )
      .then((res) => {
        
        const _numAdmins = res.data.data.filter(x => x.role === "Admin").length; 
        console.log("_numAdmins", _numAdmins);
        this.setState({
          userItems: res.data.data,
          totalItemsCount: res.data.count,
          numAdmins: _numAdmins
        });
        this.setState({ curItemStart: startpos });
      })
      .catch((err) => {
        this.props.setAuthError(err);
        //this.catchUnAuthorized(err,"get clientusers");
      });
  }

  handleEditBtn = (id) => {
    axios
      .get("./api/clientuser?id=" + id, this.tokenHeader)
      .then((res) => {
        let data = res.data;
        //console.log("handleEditBtn", data)

        if (this.state.isFirstLogin) {
          data.firstName = "";
          data.lastName = "";
          data.email = "";
        }

        this.setState({ isEditMode: true, curUserItem: data, curUserRole:data.role });

        if (data.agentGroup) {
          this.setState({ agentGroupTitel: data.agentGroup.title });
        } else {
          this.setState({ agentGroupTitel: "N/A" });
        }
      })
      .catch((err) => {
        if (this.props.isFirstLogin) {
          this.props.setAuthError(err);
        } else {
          console.log("ERR - handleEditBtn", err.message);
        }
      });
  };

  handleDeleteUser = (item) => {
      // we have enugh of Admins, lets delete
      axios
        .delete("./api/clientuser?guid=" + item.guid, this.tokenHeader)
        .then((res) => {
          this.loadAllUsers(0);
        })
        .catch((err) => {
          console.log("ERR handleDeleteInvite", err);
          this.props.setAuthError(err);
      });
  };

  handleUserItem = (event) => {
    // console.log(event.target.id, event.target.value);
    const dataType = event.target.getAttribute("data-type");
    const item = event.target.id;
    const data = event.target.value;

    const updatedItems = { ...this.state.curUserItem };
    if (dataType) {
      switch (dataType) {
        case "int":
          updatedItems[item] = parseInt(data);
          break;
        case "double":
          updatedItems[item] = parseFloat(data);
          break;
        case "bool":
          updatedItems[item] = JSON.parse(data);
          break;
        case "string":
          updatedItems[item] = data;
          break;
        default:
          updatedItems[item] = data;
          break;
      }
    } else {
      updatedItems[item] = data;
    }

    this.setState({ curUserItem: updatedItems });
  };

  handleUserItemRole = (data) => {
    const updatedItems = { ...this.state.curUserItem };
    updatedItems["role"] = data;
    this.setState({ curUserItem: updatedItems });
  };

  handleUserItemBlocked = (data) => {
    const updatedItems = { ...this.state.curUserItem };
    updatedItems["isBlocked"] = data;
    this.setState({ curUserItem: updatedItems });
  };

  handleUserItemTimeZone = (data) => {
    const updatedItems = { ...this.state.curUserItem };
    updatedItems["timeZone"] = data;
    this.setState({ curUserItem: updatedItems });
  };

  renderPagination() {
    return (
      <>
        <Divider style={{ marginTop: "10px" }} />
        <Row>
          <Button
            onClick={() => {
              if (this.state.curItemStart - this.state.curItemPage >= 0) {
                this.loadAllUsers(
                  this.state.curItemStart - this.state.curItemPage
                );
              }
            }}
          >
            {"<"}
          </Button>
          <div style={{ marginLeft: "5px", marginRight: "5px" }}>
            {" "}
            {this.state.curItemStart / this.state.curItemPage + 1} /{" "}
            {Math.ceil(this.state.totalItemsCount / this.state.curItemPage)}{" "}
          </div>
          <Button
            onClick={() => {
              if (
                this.state.curItemStart + this.state.curItemPage <
                this.state.totalItemsCount
              ) {
                this.loadAllUsers(
                  this.state.curItemStart + this.state.curItemPage
                );
              }
            }}
          >
            {">"}
          </Button>
        </Row>
      </>
    );
  }

  handleEditUserPassword = (event) => {
    const data = event.target.value;

    //console.log("handleEditUserPassword",data)

    let pwdOK = false;

    if (data === this.state.editUserPwdConfirm) {
      pwdOK = true;

      this.testPwdstrengthEditUser(data);
      this.setPasswordEditUser(data);
    }

    this.setState({ editUserPwd: data, editUserPwdChangeMatch: pwdOK });
  };

  handleEditUserPasswordConfirm = (event) => {
    const data = event.target.value;

    //console.log("handleEditUserPasswordConfirm",data)

    let pwdOK = false;
    if (data === this.state.editUserPwd) {
      pwdOK = true;

      this.testPwdstrengthEditUser(data);
      this.setPasswordEditUser(data);
    }

    this.setState({ editUserPwdConfirm: data, editUserPwdChangeMatch: pwdOK });
  };

  setPasswordEditUser(pwd) {
    const hashDigest = sha1(pwd);
    const hashDigestBase64 = Base64.stringify(hashDigest);

    // console.log("savePWD", pwd, hashDigestBase64);

    this.setState({ editUserHash: hashDigestBase64 });
  }

  testPwdstrengthEditUser(pwd) {
    const isStrong = pwd.match(
      /(?=^.{8,64}$)(?=.*\d)(?=.*[!@#$%^&_~?£*]+)(?![.\n])(?=.*[A-Z])(?=.*[a-z]).*$/
    );

    this.setState({ editUserPwdStrong: isStrong });
  }

  handleFindAgentGroupById = (agid) => {
    // console.log("handleFindAgentGroupById", agid)

    if (agid !== undefined) {
      axios
        .get("./api/users/findAgentGroup?guid=" + agid, this.tokenHeader)
        .then((res) => {
          //console.log("res.data", res.data);

          if (res.data) {
            const updatedItems = { ...this.state.curUserItem };
            updatedItems["agentGroupTitle"] = res.data.title;
            updatedItems["agentGroupGuid"] = res.data.agentGroupGUID;
            this.setState({
              curUserItem: updatedItems,
              agentGroupGUID: res.data.agentGroupGUID,
            });

            this.setState({ agentGroupTitel: res.data.title });
            pushNotification(
              "Found AgentGroup,  company: " + res.data.company,
              {
                variant: "success",
              }
            );
          }
        })
        .catch((err) => {
          if (!err) return;

          if (err.response) {
            if (err.response.status === 400) {
              alert("Entered value is not an valid AgentGroup ID", {
                variant: "warning",
              });
            }

            if (err.response.status === 404) {
              alert(
                "AgentGroup not found, please contact your Administrator to create one",
                { variant: "warning" }
              );
            }
          }

          this.props.setAuthError(err);
          //this.catchUnAuthorized(err,"handleFindAgentGroupById", true);
        });
    }
  };

  handleFindAgentGroupByTelosCare = (tlsid) => {
    //console.log("handleFindAgentGroupByTelosCare", tlsid)

    if (tlsid !== undefined) {
      axios
        .get(
          "./api/users/findAgentGroupByTelosCareId?email=" + tlsid,
          this.tokenHeader
        )
        .then((res) => {
          //console.log("res.data", res.data);
          if (res.data) {
            const updatedItems = { ...this.state.curUserItem };
            updatedItems["agentGroupTitle"] = res.data.title;
            updatedItems["agentGroupGuid"] = res.data.agentGroupGUID;
            this.setState({
              curUserItem: updatedItems,
              agentGroupGUID: res.data.agentGroupGUID,
            });

            this.setState({ agentGroupTitel: res.data.title });
            pushNotification(
              "Found AgentGroup,  company: " + res.data.company,
              {
                variant: "success",
              }
            );
          }
        })
        .catch((err) => {
          if (!err) return;

          if (err.response) {
            if (err.response.status === 400) {
              alert("Entered value is not an valid Telos Card ID", {
                variant: "warning",
              });
            }

            if (err.response.status === 404) {
              alert(
                "AgentGroup not found, please contact your Administrator to create one",
                { variant: "warning" }
              );
            }
          }

          this.props.setAuthError(err);
          //this.catchUnAuthorized(err,"handleFindAgentGroupByTelosCare", true);
        });
    }
  };

  handleResetAgentGroup = () => {
    /* const ag = {...this.state.agentGroup};
    ag.title = "N/A";
    ag.agentGroupGuid = undefined;
    this.setState({agentGroup : ag});*/
  };

  handleAddUser = () => {
    
    this.setState({ 
      newUser: {
        firstName: "",
        lastName: "",
        email: "",
        pwd: "",
        pwdCheck: "",
        role: "User",
        timeZone: "UTC",
      },
      addUserModalOpen: true 
    });
  };

  handleNewUserItem = (event) => {
    // console.log(event.target.id, event.target.value);
    const dataType = event.target.getAttribute("data-type");
    const item = event.target.id;
    const data = event.target.value;

    const updatedItems = { ...this.state.newUser };
    if (dataType) {
      switch (dataType) {
        case "int":
          updatedItems[item] = parseInt(data);
          break;
        case "double":
          updatedItems[item] = parseFloat(data);
          break;
        case "bool":
          updatedItems[item] = JSON.parse(data);
          break;
        case "string":
          updatedItems[item] = data;
          break;
        default:
          updatedItems[item] = data;
          break;
      }
    } else {
      updatedItems[item] = data;
    }

    this.setState({ newUser: updatedItems });
  };

  handleNewUserItemRole = (data) => {
    const updatedItems = { ...this.state.newUser };
    updatedItems["role"] = data;
    this.setState({ newUser: updatedItems });
  };

  handleNewUserTimeZone = (data) => {
    const updatedItems = { ...this.state.newUser };
    updatedItems["timeZone"] = data;
    this.setState({ newUser: updatedItems });
  };

  handleNewUserPassword = (event) => {
    const data = event.target.value;

    let pwdOK = false;

    if (data === this.state.newUserPwdConfirm) {
      pwdOK = true;

      this.testPwdstrengthNewUser(data);
      this.setPasswordNewUser(data);
    }

    this.setState({ newUserPwd: data, newUserPwdChangeMatch: pwdOK });
  };

  handleNewUserPasswordConfirm = (event) => {
    const data = event.target.value;

    let pwdOK = false;
    if (data === this.state.newUserPwd) {
      pwdOK = true;

      this.testPwdstrengthNewUser(data);
      this.setPasswordNewUser(data);
    }

    this.setState({ newUserPwdConfirm: data, newUserPwdChangeMatch: pwdOK });
  };

  testPwdstrengthNewUser(pwd) {
    const isStrong = pwd.match(
      /(?=^.{8,64}$)(?=.*\d)(?=.*[!@#$%^&_~?£*]+)(?![.\n])(?=.*[A-Z])(?=.*[a-z]).*$/
    );

    this.setState({ newUserPwdStrong: isStrong });
  }

  setPasswordNewUser(pwd) {
    const hashDigest = sha1(pwd);
    const hashDigestBase64 = Base64.stringify(hashDigest);

    //console.log("setPasswordNewUser", pwd, hashDigestBase64);

    this.setState({ newUserHash: hashDigestBase64 });
  }

  handleSaveNewUser = () => {
    const postData = {
      agentGroupGUID: this.state.agentGroupGUID,
      timeZone: this.state.newUser.timeZone,
      firstName: this.state.newUser.firstName,
      lastName: this.state.newUser.lastName,
      email: this.state.newUser.email,
      pwdHash: this.state.newUserHash,
      role: this.state.newUser.role,
    };

    axios
      .post("./api/clientuser", postData, this.tokenHeader)
      .then((res) => {
        this.setState({ addUserModalOpen: false });
        this.loadAllUsers(0);
      })
      .catch((err) => {
        if (err.response) {
          console.log("Add User ERROR: ", err.message, "|", err.response.data);
          pushNotification("Error ", err.response.data);
        } else {
          console.log("Add User ERROR: ", err.message);
          pushNotification("Error ", err.message);
        }
      });
  };

  renderModalAddUser() {
    let isPwdOK = "";
    let iseditUserPwdStrong = "";
    let isSaveButtonDisabled = false;

    if (this.state.newUserPwdChangeMatch) {
      if (!this.state.newUserPwdStrong) {
        iseditUserPwdStrong = "Password does not meet requirements!";
        isSaveButtonDisabled = true;
      }
    } else {
      isPwdOK = "Passwords do not match!";
      isSaveButtonDisabled = true;
    }

    return (
      <Modal
        open={this.state.addUserModalOpen}
        className="session-inviteModal"
        onEscapeKeyDown={() => this.setState({ addUserModalOpen: false })}
        buttons={[
          <button
            key="au_btnCancel"
            type="button"
            className="uic-btn btn-grey"
            onClick={() => this.setState({ addUserModalOpen: false })}
          >
            Cancel
          </button>,
          <button
            key="au_btnAdd"
            type="button"
            className="uic-btn btn-primary"
            disabled={isSaveButtonDisabled}
            onClick={() => this.handleSaveNewUser()}
            color="primary"
          >
            Save
          </button>,
        ]}
      >
        <h2 className="panel-subSection-header">Add User</h2>

        <Table
          key="au_tbl"
          alignLabelsRight
          yPad={10}
          columnWidths={[250, 450]}
          tdStyle={{ verticalAlign: "top" }}
          rows={[
            [
              "First Name",
              <input
                key="au_firstName"
                className="uic-input"
                id="firstName"
                placeholder="First Name"
                name="firstName"
                autoComplete="off"
                type="text"
                required
                defaultValue={this.state.newUser.firstName}
                onChange={this.handleNewUserItem}
                data-type="string"
                style={{ width: "100%" }}
              />,
            ],
            [
              "Last Name",
              <input
                key="au_lastName"
                className="uic-input"
                id="lastName"
                placeholder="Last Name"
                name="lastName"
                autoComplete="off"
                type="text"
                required
                defaultValue={this.state.newUser.lastName}
                onChange={this.handleNewUserItem}
                data-type="string"
                style={{ width: "100%" }}
              />,
            ],
            [
              "Email (login name)",
              <input
                key="au_email"
                className="uic-input"
                id="email"
                placeholder="Email"
                name="email"
                autoComplete="off"
                type="email"
                required
                defaultValue={this.state.newUser.email}
                onChange={this.handleNewUserItem}
                data-type="string"
                style={{ width: "100%" }}
              />,
            ],
            [
              "Password",
              <input
                key="au_pwd"
                className="uic-input"
                id="pwd"
                placeholder="Password"
                name="pwd"
                autoComplete="off"
                type="password"
                required
                defaultValue={this.newUserPwd}
                onChange={this.handleNewUserPassword}
                data-type="string"
                style={{ width: "100%" }}
              />,
            ],
            [
              "Confirm Password",
              <>
                <input
                  key="au_pwdCheck"
                  className="uic-input"
                  id="pwdCheck"
                  placeholder="Password"
                  name="pwdCheck"
                  autoComplete="off"
                  type="password"
                  required
                  defaultValue={this.newUserPwdConfirm}
                  onChange={this.handleNewUserPasswordConfirm}
                  data-type="string"
                  style={{ width: "100%" }}
                />
                <br />
                <span style={{ color: "orangered", fontSize: "14px" }}>
                  {isPwdOK}
                </span>{" "}
                <span style={{ color: "yellow", fontSize: "14px" }}>
                  {iseditUserPwdStrong}
                </span>
              </>,
            ],
            [
              "Role",
              <Select
                key="au_role"
                defaultValue={this.state.newUser.role}
                onChange={(id) => this.handleNewUserItemRole(id)}
                id="role"
                name="role"
              >
                {this.userRoles.map(({ id, label, disabled }) => (
                  <option value={id} key={id} disabled={disabled}>
                    {label}
                  </option>
                ))}
              </Select>,
            ],
            [
              "Timezone",
              <TimeZoneSelect
                value={this.state.newUser.timeZone}
                onChange={this.handleNewUserTimeZone}
              />,
            ],
          ]}
        />
      </Modal>
    );
  }

  handleUpdatedUser = () => {
    if (this.state.isFirstLogin) {
      if (
        this.state.curUserItem.firstName.toLowerCase() === "admin" ||
        this.state.curUserItem.lastName.toLowerCase() === "admin" ||
        this.state.curUserItem.email.toLowerCase() === "admin"
      ) {
        alert(
          "The word 'Admin' is not valid as 'First Name', 'Last Name', 'Email' or as 'Password'. Email must be a real e-mail address!"
        );
        return;
      }
    }

    // Don't allow blocking of the last Admin.
    if(this.state.curUserItem.isBlocked && this.state.curUserItem.role === "Admin" && this.state.numAdmins < 2) {
      
      alert("Cannot block the only 'Admin' assigned user", {
        variant: "warning",
      });
      return;
    }

    // Don't allow changing role for the last Admin.
    if(this.state.curUserRole === "Admin" && this.state.curUserItem.role !== "Admin" && this.state.numAdmins < 2) {
      
        alert("Cannot change role for the only 'Admin' assigned user", {
        variant: "warning",
      });
      return;
    }

    let _editUserHash = "";

    if (this.state.editUserPwd === "" && this.state.editUserPwdConfirm === "") {
      _editUserHash = "";
    } else {
      _editUserHash = this.state.editUserHash;
    }

    const postData = {
      id: parseInt(this.state.curUserItem.clientUserId),
      guid: this.state.curUserItem.userGuid,
      firstName: this.state.curUserItem.firstName,
      lastName: this.state.curUserItem.lastName,
      agentGroupGuid: this.state.agentGroupGUID,
      role: this.state.curUserItem.role,
      isBlocked: this.state.curUserItem.isBlocked,
      timeZone: this.state.curUserItem.timeZone,
      email: this.state.curUserItem.email,
      hash: _editUserHash,
      isFirstLogin: false,
    };

    
    axios
      .put("./api/users/updateuser", postData, this.tokenHeader)
      .then((res) => {
        

        if (this.state.isFirstLogin) {
          this.setState({
            isEditMode: false,
            editUserPwd: "",
            editUserPwdConfirm: "",
          });
          this.redirectToLogin();
        } else {
          this.loadAllUsers(this.state.curItemStart);
          this.setState({
            isEditMode: false,
            editUserPwd: "",
            editUserPwdConfirm: "",
          });
        }
      })
      .catch((err) => {
        this.props.setAuthError(err);
      });
  };

  logoutUser = () => {
    this.props.setIsLoggedIn(false);
    this.props.setBakedToken("");
  };

  validateEmail = (email) => {
    var re = /\S+@\S+\.\S+/;
    return re.test(email);
  };

  render() {
    //console.log("curItemStart", this.state.curItemStart, "curItemPage",  this.state.curItemPage, "totalItemsCount", this.state.totalItemsCount)

    let isPwdOK = "";
    let iseditUserPwdStrong = "";
    let isSaveButtonDisabled = false;
    let pwdInfo = "(leave blank to keep same)";
    let emailInvalid = "";
    let panelTitle = "Edit User";
    let emailInfo = "(email is the login name)";

    if (this.state.editUserPwdChangeMatch) {
      if (!this.state.editUserPwdStrong) {
        iseditUserPwdStrong = (
          <>
            <WarningIcon
              style={{
                color: "orange",
                top: "3px",
                position: "relative",
                height: "18px",
              }}
            />{" "}
            Password does not meet requirements!
          </>
        );
        isSaveButtonDisabled = true;
      }
    } else {
      isPwdOK = (
        <>
          <WarningIcon
            style={{
              color: "orange",
              top: "3px",
              position: "relative",
              height: "18px",
            }}
          />{" "}
          Passwords do not match!
        </>
      );
      isSaveButtonDisabled = true;
    }

    if (
      this.state.isFirstLogin === false &&
      this.state.editUserPwd === "" &&
      this.state.editUserPwdConfirm === ""
    ) {
      isPwdOK = "";
      iseditUserPwdStrong = "";
      isSaveButtonDisabled = false;
    }

    if (
      this.state.isFirstLogin === true &&
      this.state.editUserPwd === "" &&
      this.state.editUserPwdConfirm === ""
    ) {
      isPwdOK = "";
      iseditUserPwdStrong = "";
      isSaveButtonDisabled = true;
    }

    if (
      this.state.curUserItem.firstName &&
      this.state.curUserItem.lastName &&
      this.state.curUserItem.email
    ) {
      //isSaveButtonDisabled = false;
    } else {
      isSaveButtonDisabled = true;
    }

    // check if email is valid, if entered..
    if (this.state.isFirstLogin && this.state.curUserItem.email) {
      const emailOK = this.validateEmail(this.state.curUserItem.email);
      if (emailOK) {
        emailInvalid = "";
      } else {
        emailInvalid = (
          <WarningIcon
            style={{
              color: "orange",
              top: "3px",
              position: "relative",
              height: "18px",
            }}
          />
        );
      }
    }

    if (this.state.isFirstLogin) {
      pwdInfo = "";
      panelTitle = "Add Administrator Account";
      emailInfo = "(email is the login name)"
    }

    if (this.state.isEditMode) {
      return (
        <>
          <Grid container spacing={2}>
            <Grid item sm={8}>
              <Panel title={panelTitle}>
                <Table
                  alignLabelsLeft
                  yPad={10}
                  rows={[
                    [
                      "First Name*",
                      <input
                        className="uic-input"
                        id="firstName"
                        name="firstName"
                        placeholder="First Name"
                        autoComplete="off"
                        type="text"
                        required
                        maxLength="128"
                        value={this.state.curUserItem.firstName}
                        onChange={this.handleUserItem}
                        data-type="string"
                        style={{ width: "300px" }}
                      />,
                    ],
                    [
                      "Last Name*",
                      <input
                        className="uic-input"
                        id="lastName"
                        name="lastName"
                        placeholder="Last Name"
                        autoComplete="off"
                        type="text"
                        required
                        maxLength="128"
                        value={this.state.curUserItem.lastName}
                        onChange={this.handleUserItem}
                        data-type="string"
                        style={{ width: "300px" }}
                      />,
                    ],
                    [
                      "Email",
                      <>
                        <input
                          className="uic-input"
                          id="email"
                          name="email"
                          placeholder="Email"
                          autoComplete="off"
                          type="email"
                          required
                          maxLength="256"
                          value={this.state.curUserItem.email}
                          onChange={this.handleUserItem}
                          data-type="string"
                          style={{ width: "300px" }}
                         // disabled={!this.state.isFirstLogin}
                        /> {" "}
                        <span>{emailInvalid} {emailInfo}</span>
                      </>,
                    ],
                    [
                      "Password*",
                      <>
                        <input
                          className="uic-input"
                          id="password"
                          name="password"
                          placeholder="Password"
                          autoComplete="off"
                          type="password"
                          required
                          maxLength="64"
                          value={this.state.editUserPwd}
                          onChange={this.handleEditUserPassword}
                          data-type="string"
                          style={{ width: "300px" }}
                        />{" "}
                        <span>{pwdInfo}</span>
                      </>,
                    ],
                    [
                      "Confirm Password*",
                      <>
                        <input
                          className="uic-input"
                          id="passwordConfirm"
                          name="passwordConfirm"
                          placeholder="Confirm Password"
                          autoComplete="off"
                          type="password"
                          required
                          maxLength="64"
                          value={this.state.editUserPwdConfirm}
                          onChange={this.handleEditUserPasswordConfirm}
                          data-type="string"
                          style={{ width: "300px" }}
                        />{" "}
                        <span style={{ color: "orangered" }}>{isPwdOK}</span>{" "}
                        <span style={{ color: "yellow" }}>
                          {iseditUserPwdStrong}
                        </span>
                      </>,
                    ],
                    [
                      "Role*",
                      <Select
                        value={this.state.curUserItem.role}
                        onChange={(id) => this.handleUserItemRole(id)}
                        id="role"
                        name="role"
                        //disabled={this.state.isFirstLogin || this.state.curUserRole === "Admin"}
                        disabled={this.state.isFirstLogin}
                      >
                        {this.userRoles.map(({ id, label, disabled }) => (
                          <option value={id} key={id} disabled={disabled}>
                            {label}
                          </option>
                        ))}
                      </Select>,
                    ],
                    [
                      "Blocked",
                      <Toggle
                        showText
                        onChange={(data) => this.handleUserItemBlocked(data)}
                        defaultChecked={this.state.curUserItem.isBlocked}
                        id="isBlocked"
                        name="isBlocked"
                        disabled={this.state.isFirstLogin}
                      />,
                    ],
                    [
                      "Timezone*",
                      <TimeZoneSelect
                        value={this.state.curUserItem.timeZone}
                        onChange={this.handleUserItemTimeZone}
                      />,
                    ],
                    [
                      "AgentGroup",
                      <>
                        <span>{this.state.agentGroupTitel}</span>
                        {this.state.isFirstLogin === false && (
                          /* only show icon if not in 'isFirstLogin' */ <IconButton
                            icon={AddCircleOutline}
                            tooltip='Find AgentGroup by "Telos Care ID"'
                            style={{ marginLeft: "10px" }}
                            onClick={async (event) => {
                              event.preventDefault();
                              const data = await prompt(
                                <>Please enter your "Telos Care ID"</>,
                                {
                                  autoSelect: true,
                                }
                              );

                              if (!data) return;
                              this.handleFindAgentGroupByTelosCare(data);
                            }}
                          />
                        )}
                      </>,
                    ],

                    [<Divider />],
                    [
                      "Last Login",
                      <>
                        {this.state.isFirstLogin === false && (
                          /* only show icon if not in 'isFirstLogin' */ <>
                            <span>
                              {formatDistanceToNow(
                                new Date(
                                  new Date(
                                    this.state.curUserItem.lastLoginDate
                                  ).toString() + "Z"
                                )
                              )}{" "}
                              ago
                            </span>
                            <InfoTooltip
                              children={
                                "UTC: " +
                                this.state.curUserItem.lastLoginDate.substr(
                                  0,
                                  19
                                )
                              }
                              style={{ marginLeft: "10px" }}
                            />
                          </>
                        )}
                      </>,
                    ],
                  ]}
                />

                <Divider style={{ marginTop: "20px" }} />

                {!this.state.isFirstLogin && (
                  <Button
                    onClick={() => {
                      this.setState({ isEditMode: false });
                    }}
                  >
                    Cancel
                  </Button>
                )}

                {this.state.isFirstLogin && (
                  <Button
                    onClick={() => {
                      
                      this.redirectToLogin();
                    }}
                  >
                    <ArrowBackIosIcon />
                    Cancel
                  </Button>
                )}

                <Button
                  color="primary"
                  disabled={isSaveButtonDisabled}
                  style={{ marginLeft: "5px" }}
                  onClick={() => {
                    this.handleUpdatedUser();
                  }}
                >
                  <SaveIcon /> Save
                </Button>
              </Panel>
            </Grid>
            <Grid item sm={4}>
              <Panel title="Info">
                <h3 style={{ color: "#dededecc" }}>Password Requirements</h3>
                <Divider style={{ marginBottom: "10px" }} />
                <ul className="list" style={{ color: "#dedede" }}>
                  <li style={{ color: "#dedede", marginBottom: "5px" }}>
                    Length must be from{" "}
                    <span style={{ color: "#96ccf3" }}>8</span> to{" "}
                    <span style={{ color: "#96ccf3" }}>64</span> characters
                  </li>
                  <li style={{ color: "#dedede", marginBottom: "5px" }}>
                    Must contain one or more{" "}
                    <span style={{ color: "#96ccf3" }}>uppercase</span> letters
                  </li>
                  <li style={{ color: "#dedede", marginBottom: "5px" }}>
                    Must contain one or more{" "}
                    <span style={{ color: "#96ccf3" }}>lowercase</span> letters
                  </li>
                  <li style={{ color: "#dedede", marginBottom: "5px" }}>
                    Must contain one or more{" "}
                    <span style={{ color: "#96ccf3" }}>numbers</span>
                  </li>
                  <li>
                    Must contain one or more of these characters&nbsp;&nbsp;
                    <span style={{ color: "#96ccf3" }}>
                      {"! @ # $ % ^ & * ? _ ~ £"}
                    </span>
                  </li>
                </ul>
              </Panel>
            </Grid>
          </Grid>
        </>
      );
    } else {
      return (
        <>
          {this.renderModalAddUser()}

          {!this.state.isFirstLogin && (
            <Panel
              title={
                <>
                  <span style={{ fontSize: "18px" }}>Users</span>{" "}
                  <AddBoxIcon
                    visibility={!this.state.allowAddUser ? "hidden" : "showing"}
                    onClick={() => this.handleAddUser()}
                    style={{
                      position: "relative",
                      top: "5px",
                      color: "#48ACF4",
                      cursor: "pointer",
                    }}
                  />
                </>
              }
            >
              <Table
                params
                striped
                alignLabelsLeft="left"
                headers={[
                  "Firstname",
                  "Lastname",
                  "Email",
                  "Agent Group",
                  "Time Zone",
                  "Role",
                  "Blocked",
                  "",
                ]}
                columnWidths={[250, 250, 350, 300, 200, 100, 80, 100]}
                rows={this.state.userItems.map((item) => [
                  item.firstName,
                  item.lastName,
                  item.email,
                  item.agentGroupTitle,
                  item.timeZone,
                  item.role,
                  <Checkbox
                    key={`chk_${item.guid}`}
                    disabled
                    color="primary"
                    checked={item.isBlocked}
                  />,
                  <React.Fragment>
                    <IconButton
                      onClick={() => this.handleEditBtn(item.id)}
                      icon={EditIcon}
                      tooltip="Edit User"
                    />

                    <IconButton
                      style={{ marginLeft: "5px" }}
                      key={`btnDel_${item.guid}`}
                      icon={DeleteIcon}
                      tooltip="Remove User"
                      onClick={async (event) => {
                        event.preventDefault();
                        
                        // if user is Admin, check it's not the last admin...
                        if(item.role === "Admin" && this.state.numAdmins < 2) {
                            alert("Cannot delete the only 'Admin' assigned user", {
                            variant: "warning",
                          });
                    
                        } else {
                          //Okay passed forst check, lets ask if you really want to delete the user
                          if (
                            await confirm(
                              <>
                                Sure you want to remove
                                <br />
                                <b>
                                  {item.firstName} {item.lastName}
                                </b>{" "}
                                ?
                              </>,
                              { variant: "warning" }
                            )
                          ) {
                            this.handleDeleteUser(item);
                          }
                        }
                      }}
                    />
                   
                  </React.Fragment>,
                ])}
              />
              {this.renderPagination()}
            </Panel>
          )}
        </>
      );
    }
  }
}

export default Users;
