/*
  VU metering Class using AudioWorklet
*/
class TelosWebRTCvumeter extends SimpleEmitter {
    constructor(stream, audioctx, workletPath) {
        super()

        if (!audioctx) {
            console.log('ERR - meter')
            return
        }

        console.log('TelosWebRTCvumeter...')

        this.startVU(stream, audioctx, workletPath)
        this.vuNode = null
        this.audioSource = null
        this._audioctx = audioctx
    }

    async startVU(stream, audioctx, workletPath) {
        console.log('workletPath:', workletPath)

        await audioctx.audioWorklet.addModule(workletPath + 'vuMeterProc.js')

        console.log('AudioWorklet loaded!')

        try {
            this.audioSource = audioctx.createMediaStreamSource(stream)

            this.vuNode = new window.AudioWorkletNode(audioctx, 'vuMeterProc')
            this.audioSource.connect(this.vuNode)
            this.vuNode.port.onmessage = (event) => {
                this.emit('meter', event.data)
            }
        } catch (err) {
            console.log('ERROR creating VU', err)
        }
    }

    closeVU() {
        if (this.vuNode) {
            this.vuNode.disconnect()
            this.audioSource.disconnect()
        }
    }
}

/*
  Telos WebRTC
*/
export class TelosWebRTC extends SimpleEmitter {
    constructor() {
        super()
        this.version = '0.9.3' // 2022-12-13 (based on 0.8.26)
        this.room_id = ''
        this.connectCfg = {}
        this.initCfg = { autoResume: false, debug: false }
        this.peer_id = 'browser-?'
        this.peerIdBase = 'browser-' + this.generateUUID()
        this.peerIdCount = 0
        this.peer_title = ''
        this.room_id = 'room-?'
        this.ws_url = ''
        this.ws_conn = null
        this.webrtccStatus = {
            peer: this.peer_id,
            info: 'uninitialized',
            peers: {}
        }
        this.audioContext = null
        this.isInited = false
        this.isStopped = false
        this.selAudioIn = undefined
        this.selAudioOut = undefined
        this.localStereo = false
        this.localBitRate = 32
        this.remoteStereo = false
        this.isDebug = false
        this.isInitDebug = false
        this.useData = true
        this.confbrowsers = true
        this.defaultConstraints = false
        this.useAgc = false
        this.useEcho = false
        this.useNoice = false
        this.localAudioPlayer = null

        this.constraints = { audio: true, video: false }
        this.peerConnections = {}
        this.dataChannel = null

        /* this.rtc_configuration = {
          iceServers: [
            { urls: 'stun:stun.l.google.com:19302' }
          ]
        } */
        this.rtc_configuration = {}

        this.localMediaPromise = null
        this.localMediaStream = null
        this.localMediaStreamEnable = true
        this.remoteMediaStream = null
        this.iceConType = 0 // 0 = N/A (no connection), 1 = Host, 2 = Reflexive (server), 3 = Reflexive (peer), 4 = Relay (TURN)
        this.iceConTypeName = ''
        this.getStats = true
        this.mediadevlist = null
        this.autoDisconnect = true
        this.useVUmeters = false
        this.vuClassLocal = null
        this.vuClassRemote = null
        this.autoResume = false
        this.autoResumeCount = 0
        this.autoResumeMax = 10
        this.isInitCfg = false
        this.lastConnectionState = {
            tp: 1,
            topic: 'ConnectionState',
            msg: '',
            data: 0
        }
        this.peerHasLeft = false

        this.autoReconnect = true
        this.autoReconnectWait = 1500
        this.upTime = 0
        this.connectTime = 0
        this.inviteId = '00000000-0000-0000-0000-000000000000'
        this.iceStatus = { mode: 'n/a' }
        this.useIceFromSignaling = true
        this.maxNumIce = 4
        this.workletPath = './'
        this.promptNewUser = true

        this.statsTimerIntervalMs = 1000
        this.statsTimer = null
        this.isRoomCreator = false
        this.remotePeerConnection = null
        this.logToServer = false
        this.allowSDPmod = false
        this.isMonitorOnly = false
            

        // Start STATS
        this.localAudioCandidateId = ''
        this.localAudioCandidateType = ''
        this.localAudioNetworkType = ''
        this.localAudioProtocol = ''
        this.localAudioIP = ''
        this.localAudioPort = ''
        this.localAppCandidateId = ''
        this.localAppCandidateType = ''
        this.localAppNetworkType = ''
        this.localAppProtocol = ''
        this.localAppIP = ''
        this.localAppPort = ''
        this.localAudioMimeType = ''
        this.localAudioClockRate = ''
        this.localAudioChannels = ''
        this.localAudioPayloadType = ''
        this.remoteAppCandidateId = ''
        this.remoteAppCandidateType = ''
        this.remoteAppProtocol = ''
        this.remoteAppIP = ''
        this.remoteAppPort = ''
        this.remoteAudioCandidateId = ''
        this.remoteAudioCandidateType = ''
        this.remoteAudioProtocol = ''
        this.remoteAudioNetworkType = ''
        this.remoteAudioIP = ''
        this.remoteAudioPort = ''
        this.remoteAudioMimeType = ''
        this.remoteAudioClockRate = ''
        this.remoteAudioChannels = ''
        this.remoteAudioPayloadType = ''
        this.localInboundJitter = ''
        this.localInboundPacketsReceived = ''
        this.localInboundPacketsLost = ''
        this.localInboundPacketsDiscarded = ''
        this.localOutboundPacketsSent = ''
        this.localOutboundRetransmittedPacketsSent = ''
        this.remoteInboundJitter = ''
        this.remoteInboundPacketsLost = ''
        this.remoteInboundRoundTripTime = ''
        this.remoteInboundTotalRoundTripTime = ''
        this.remoteOutboundPacketsSent = ''
        this.remoteOutboundTotalRoundTripTime = ''
        this.fingerprintAlgorithm = ''
        // End STATS
    }

    getVersion() {
        return this.version
    }

    getBrowser() {
        return 'N/A'
    }

    onLocalDescription = (peerId, peerConnection) => {
        let sdp = { sdp: peerConnection.localDescription }
        if (!this.webrtccStatus.peers[peerId]) { this.webrtccStatus.peers[peerId] = {} }
        this.webrtccStatus.peers[peerId].localDescription =
            peerConnection.localDescription.sdp.split('\r\n')

        const _status = {
            tp: 6,
            topic: 'onLocalDescription',
            msg: '',
            data: { localId: this.peer_id, remoteId: peerId }
        }

        this.emit('status', _status)
        this.ws_conn.send('ROOM_PEER_MSG ' + peerId + ' ' + JSON.stringify(sdp))
    };

    webrtccSend = (msg) => {
        function webrtccPeerSend(peerId, data) {
            this.ws_conn.send('ROOM_PEER_MSG ' + peerId + ' ' + data)
        }
        let data = JSON.stringify({ data: msg })

        for (let peerId in this.webrtccStatus.peers) {
            webrtccPeerSend(peerId, data)
        }
    };

    generateOffer = (peerId, peerConnection) => {
        const self = this

        peerConnection
            .createOffer()
            .then(function (offer) {
                // offer.sdp = tweekSPD(offer.sdp);
                peerConnection
                    .setLocalDescription(offer)
                    .then(function () {
                        self.onLocalDescription(peerId, peerConnection)
                    })
                    .catch((err) => {
                        const _error5 = {
                            tp: 5,
                            topic: 'generateOffer 1',
                            msg: '',
                            data: err
                        }

                        self.emit('error', _error5)
                        self.doServerLog('error', _error5)
                    })
            })
            .catch((err) => {
                const _error6 = {
                    tp: 6,
                    topic: 'generateOffer 2',
                    msg: '',
                    data: err
                }

                self.emit('error', _error6)
                self.doServerLog('error', _error6)
            })
    };

    handleDataChannelOpen = (event) => {
        if (this.isDebug) {
            const _debug = {
                tp: 2,
                topic: 'Receive DataChannelOpen',
                msg: '',
                data: event
            }

            this.emit('debug', _debug)
        }
    };

    handleDataChannelMessageReceived = (event) => {
        if (this.isDebug) {
            const _debug = {
                tp: 3,
                topic: 'dataChannel.OnMessage',
                msg: '',
                data: event
            }

            this.emit('debug', _debug)
        }

        if (typeof event.data === 'string' || event.data instanceof String) {
            this.emit('data', event.data)
        } else {
            this.emit('data-bin', event.data)
        }
    };

    handleDataChannelError = (error) => {
        if (error.error.message === 'Transport channel closed') {
            return
        }

        const _error = {
            tp: 1,
            topic: 'DataChannelError',
            msg: '',
            data: error
        }

        if (this.isDebug) {
            this.emit('error', _error)
        }
        this.doServerLog('error', _error)
    };

    handleDataChannelClose = (event) => {
        const _status = {
            tp: 7,
            topic: 'DataChannelClose',
            msg: '',
            data: event
        }

        this.emit('status', _status)
        this.doServerLog('status', _status)
    };

    onDataChannel = (event) => {
        if (this.isDebug) {
            const _debug = {
                tp: 10,
                topic: 'Receive Data channel created',
                msg: '',
                data: event
            }

            this.emit('debug', _debug)
        }

        let receiveChannel = event.channel
        receiveChannel.onopen = this.handleDataChannelOpen
        receiveChannel.onmessage = this.handleDataChannelMessageReceived
        receiveChannel.onerror = this.handleDataChannelError
        receiveChannel.onclose = this.handleDataChannelClose
    };

    onServerError = (event) => {
        const _error = {
            tp: 2,
            topic: 'Unable to connect to server',
            msg: '',
            data: event
        }

        this.emit('error', _error)
        this.doServerLog('error', _error)
    };

    // ICE candidate received from peer, add it to the peer connection
    onIncomingICE = (peerConnection, ice) => {
        var candidate = new window.RTCIceCandidate(ice)
        peerConnection.addIceCandidate(candidate).catch((err) => {
            const _error = {
                tp: 3,
                topic: 'onIncomingICE',
                msg: '',
                data: err
            }

            this.emit('error', _error)
            this.doServerLog('error', _error)
        })
    };

    createPeerConnection = (offer) => {
        if (this.isDebug) {
            const _debug6 = {
                tp: 6,
                topic: 'createPeerConnection',
                msg: '',
                data: offer
            }

            this.emit('debug', _debug6)

            const _debug7 = {
                tp: 7,
                topic: 'RTC Configuration',
                msg: '',
                data: this.rtc_configuration
            }

            this.emit('debug', _debug7)

            const _debug19 = {
                tp: 19,
                topic: 'Datachannel enabled',
                msg: '',
                data: this.useData
            }

            this.emit('debug', _debug19)
        }

        let peerConnection = new window.RTCPeerConnection(this.rtc_configuration)
        this.remotePeerConnection = peerConnection

        if (this.useData) {
            if (offer) {
                this.dataChannel = peerConnection.createDataChannel('channel')
                this.dataChannel.onopen = this.handleDataChannelOpen
                this.dataChannel.onmessage = this.handleDataChannelMessageReceived
                this.dataChannel.onerror = this.handleDataChannelError
                this.dataChannel.onclose = this.handleDataChannelClose
            }
            peerConnection.ondatachannel = this.onDataChannel
        }

        peerConnection.ontrack = this.onRemoteTrack
        peerConnection.onconnectionstatechange = this.onConnectionStateChange
        peerConnection.oniceconnectionstatechange = this.onIceConnectionStateChange
        peerConnection.onicegatheringstatechange = this.onIceGatheringStateChange
        peerConnection.onnegotiationneeded = this.onNegotiationNeeded

        return peerConnection
    };

    sendData(data) {
        let signalingFallback = true
        if (this.useData) {
            if (this.dataChannel && this.dataChannel.readyState === 'open') {
                this.dataChannel.send(data)
                signalingFallback = false
            }
        }
        if (signalingFallback && this.ws_conn) {
            let msg = JSON.stringify({ data: data })
            for (let peerId in this.webrtccStatus.peers) {
                this.ws_conn.send('ROOM_PEER_MSG ' + peerId + ' ' + msg)
            }
        }
    }

    async getLocalMedia() {
        // console.log('getLocalMedia - useVUmeters', this.useVUmeters)

        try {
            this.webrtccStatus.constraints = this.constraints
            this.localMediaPromise = navigator.mediaDevices.getUserMedia(
                this.constraints
            )
            this.localMediaPromise.then((stream) => {
                this.localMediaStream = stream
                stream.getTracks().forEach((track) => {
                    track.enabled = this.localMediaStreamEnable
                })

                if (this.useVUmeters) {
                    this.vuClassLocal = new TelosWebRTCvumeter(
                        stream,
                        this.audioContext,
                        this.workletPath
                    )
                    this.vuClassLocal.on('meter', (data) => {
                        this.emit('localVu', data)
                    })
                }
            })
        } catch (err) {
            const _error10 = {
                tp: 10,
                topic: 'getLocalMedia',
                msg: 'Are you using HTTPS',
                data: err
            }

            this.emit('error', _error10)
            this.doServerLog('error', _error10)
        }

        if (this.isDebug) {
            const _debug = {
                tp: 8,
                topic: 'Media Contraints',
                msg: '',
                data: this.constraints
            }

            this.emit('debug', _debug)
        }
    }

    resetVU = () => {
        const _json1 = { rmsL: 0, rmsR: 0, dbL: -50, dbR: -50, percL: 0, percR: 0 }

        const _json2 = { rmsL: 0, rmsR: 0, dbL: -50, dbR: -50, percL: 0, percR: 0 }

        this.emit('localVu', _json1)
        this.emit('remoteVu', _json2)

        setTimeout(() => {
            this.emit('localVu', _json1)
            this.emit('remoteVu', _json2)
        }, 500)
    };

    answerPeer = (peerId, sdp) => {
        // send stereo to remote and bitrate fix?

        if (this.allowSDPmod) {
            let sdpstr =
                'x-google-min-bitrate=' +
                this.localBitRate +
                '; x-google-max-bitrate=' +
                this.localBitRate +
                '; x-google-start-bitrate=' +
                this.localBitRate +
                '; maxaveratebitrate=' +
                this.localBitRate * 1024

            if (this.localStereo) {
                sdpstr += ';stereo=1; sprop-stereo=1; cbr=1'
            } else {
                sdpstr += ';stereo=0; sprop-stereo=0;'
            }

            sdp.sdp = sdp.sdp.replace('sprop-stereo=1', sdpstr)
        }

        let peerConnection = this.createPeerConnection(true)

        const self = this

        if (peerConnection.signalingState !== 'stable') {
            return peerConnection /* TODO */
        }

        peerConnection
            .setRemoteDescription(sdp)
            .then(function () {
                self.webrtccStatus.peers[peerId].remoteDescription =
                    sdp.sdp.split('\r\n')

                self.localMediaPromise
                    .then(function (stream) {
                        stream
                            .getTracks()
                            .forEach((track) => peerConnection.addTrack(track, stream))
                    })
                    .then(function () {
                        return peerConnection.createAnswer()
                    })
                    .then(function (answer) {
                        // shall we receive stereo?
                        if (self.allowSDPmod) {
                            if (self.remoteStereo) {
                                answer.sdp = answer.sdp.replace(
                                    'useinbandfec=1',
                                    'useinbandfec=1; stereo=1; maxaveragebitrate=510000'
                                ) // set local audio so it can receive stereo and up to 510kbps
                            } else {
                                answer.sdp = answer.sdp.replace(
                                    'useinbandfec=1',
                                    'useinbandfec=1; maxaveragebitrate=510000'
                                ) // set local audio to receive mono up to 510kbps
                            }
                        }
                        return peerConnection.setLocalDescription(answer)
                    })
                    .then(function () {
                        self.onLocalDescription(peerId, peerConnection)
                    })

                if (self.isDebug) {
                    const _debug11 = {
                        tp: 11,
                        topic: 'Got SDP offer',
                        msg: { peerId: peerId },
                        data: sdp
                    }

                    self.emit('debug', _debug11)
                }
            })
            .catch((err) => {
                const _error = {
                    tp: 4,
                    topic: 'answerPeer',
                    msg: { peerId: peerId },
                    data: err
                }

                this.emit('error', _error)
                this.doServerLog('error', _error)
            })

        peerConnection.onicecandidateerror = (event) => {
            const iceErr = {
                address: event.address,
                errorCode: event.errorCode,
                errorText: event.errorText,
                port: event.port,
                type: event.type,
                url: event.url
            }

            const _error = {
                tp: 3,
                topic: 'ICE',
                msg: 'onicecandidateerror',
                data: iceErr
            }

            this.emit('error', _error)
            this.doServerLog('error', _error)
        }

        peerConnection.onicecandidate = (event) => {
            // We have a candidate, send it to the remote party with the
            // same uuid
            if (event.candidate == null) {
                const _status = {
                    tp: 8,
                    topic: 'ICE Candidate',
                    msg: this.iceConTypeName,
                    data: this.iceConType
                }

                this.emit('status', _status)
                this.doServerLog('status', _status)

                return
            }

            if (event.candidate) {
                if (event.candidate.candidate.indexOf('host') > 0) {
                    this.iceConType = 1
                    this.iceConTypeName = 'host'
                }

                if (event.candidate.candidate.indexOf('srflx') > 0) {
                    this.iceConType = 2
                    this.iceConTypeName = 'srflx'
                }

                if (event.candidate.candidate.indexOf('prflx') > 0) {
                    this.iceConType = 3
                    this.iceConTypeName = 'prflx'
                }

                if (event.candidate.candidate.indexOf('relay') > 0) {
                    this.iceConType = 4
                    this.iceConTypeName = 'relay'
                }

                if (
                    this.iceConType !== 1 &&
                    this.iceConType !== 2 &&
                    this.iceConType !== 3 &&
                    this.iceConType !== 4
                ) {
                    this.iceConType = 0
                    this.iceConTypeName = 'n/a'
                }

                if (this.isDebug) {
                    // console.log("ICE Candidate was null, done. Ice Connetion Type:", this.iceConType);

                    // parse Ice Candidate
                    const iceCandidate = event.candidate.candidate.split(' ')
                    if (iceCandidate.length > 8) {
                        const cIp = iceCandidate[4].trim()
                        const cPort = iceCandidate[5].trim()
                        const cType = iceCandidate[7].trim()
                        const cMode = iceCandidate[8].trim()

                        const _debug = {
                            tp: 9,
                            topic: 'ICE Candidate Details',
                            msg: { peerId: peerId },
                            data: { ip: cIp, port: cPort, type: cType, mode: cMode }
                        }

                        this.emit('debug', _debug)
                    }
                }
            } else {
                if (this.isDebug) {
                    // console.log("ICE  - End of candidate generation. Ice Connetion Type:", this.iceConType);

                    const _debug18 = {
                        tp: 18,
                        topic: 'ICE - End of candidate generation',
                        msg: { peerId: peerId },
                        data: this.iceConType
                    }

                    self.emit('debug', _debug18)
                }
            }

            this.ws_conn.send(
                'ROOM_PEER_MSG ' +
                peerId +
                ' ' +
                JSON.stringify({ ice: event.candidate })
            )
        }

        return peerConnection
    };

    callPeer = (peerId) => {
        if (this.isDebug) {
            const _debug = {
                tp: 12,
                topic: 'Calling peer',
                msg: '',
                data: peerId
            }

            this.emit('debug', _debug)
        }

        let peerConnection = this.createPeerConnection(false)

        const self = this

        this.localMediaPromise
            .then(function (stream) {
                stream.getTracks().forEach(function (track) {
                    peerConnection.addTrack(track, stream)
                })

                self.generateOffer(peerId, peerConnection)
            })
            .catch((err) => {
                const _error7 = {
                    tp: 7,
                    topic: 'getUserMedia',
                    msg: '',
                    data: err
                }

                this.emit('error', _error7)
                this.doServerLog('error', _error7)
            })

        peerConnection.onicecandidate = (event) => {
            // We have a candidate, send it to the remote party with the
            // same uuid
            if (event.candidate == null) {
                if (this.isDebug) {
                    console.log('ICE Candidate was null, done')
                }
                return
            }
            this.ws_conn.send(
                'ROOM_PEER_MSG ' +
                peerId +
                ' ' +
                JSON.stringify({ ice: event.candidate })
            )
        }
        return peerConnection
    };

    onRemoteTrack = (event) => {
        if (this.isDebug) {
            const _debug16 = {
                tp: 16,
                topic: 'onRemoteTrack',
                msg: '',
                data: event
            }

            this.emit('debug', _debug16)
        }

        if (this.localAudioPlayer) {
            if (this.localAudioPlayer.srcObject !== event.streams[0]) {
                if (this.isDebug) {
                    const _debug17 = {
                        tp: 17,
                        topic: 'LocalAudio stream',
                        msg: '',
                        data: event.streams[0]
                    }

                    this.emit('debug', _debug17)
                }

                this.remoteMediaStream = event.streams[0]
                this.localAudioPlayer.srcObject = event.streams[0]
                this.localAudioPlayer.oncanplay = (event) => {
                    this.localAudioPlayer.play()
                }

                if (this.useVUmeters) {
                    this.vuClassRemote = new TelosWebRTCvumeter(
                        event.streams[0],
                        this.audioContext,
                        this.workletPath
                    )
                    this.vuClassRemote.on('meter', (data) => {
                        this.emit('remoteVu', data)
                    })
                }

                if (event.streams[0]) {
                    const status1x12 = {
                        tp: 1,
                        topic: 'ConnectionState',
                        msg: 'Remote MediaStream',
                        data: 12,
                        data2: event.streams[0]
                    }
                    this.lastConnectionState = status1x12
                    this.emit('status', status1x12)
                    this.doServerLog('status', status1x12)

                    this.sendPeerInfo(this.peer_id)
                }
            }
        }
    };

    onServerMessage = (event) => {
        let vals = event.data.match(/(\w+)\s?(\S+)?\s?(.+)?/)

        if (this.isDebug) {
            const _debug = {
                tp: 1,
                topic: 'onServerMessage',
                msg: vals,
                data: event.data
            }

            this.emit('debug', _debug)
        }

        let cmd = vals[1]
        let id = vals[2]
        let smsg = vals[3]

        switch (cmd) {
            case 'HELLO':
                const _status = {
                    tp: 9,
                    topic: 'Registered with server, waiting for call',
                    msg: cmd,
                    data: vals
                }
                this.emit('status', _status)
                this.doServerLog('status', _status)

                this.ws_conn.send('ROOM ' + this.room_id)
                return
            case 'ROOM_OK':
                let peers = event.data.split(' ')
                for (let i = 1; i < peers.length; i++) {
                    let id = peers[i]
                    this.webrtccStatus.peers[id] = {}
                    if (this.confbrowsers) {
                        if (id && id.match(/^browser-/)) {
                            this.peerConnections[id] = this.callPeer(id)
                        }
                    }
                    const _status10 = {
                        tp: 10,
                        topic: 'Peer in room',
                        msg: cmd,
                        data: vals
                    }
                    this.emit('status', _status10)
                    this.doServerLog('status', _status10)
                }
                return
            case 'ROOM_PEER_JOINED':
                if (this.autoDisconnect && id && id.match(/^browser-/)) {
                    this.closeConnection()

                    const status1x8 = {
                        tp: 1,
                        topic: 'ConnectionState',
                        msg: 'Disconnected by other peer joining session',
                        data: 8
                    }
                    this.lastConnectionState = status1x8
                    this.emit('status', status1x8)
                    this.doServerLog('status', status1x8)
                } else {
                    this.webrtccStatus.peers[id] = {}

                    const _status11 = {
                        tp: 11,
                        topic: 'Peer has joined the room',
                        msg: cmd,
                        data: vals
                    }
                    this.emit('status', _status11)
                    this.doServerLog('status', _status11)
                }

                return
            case 'ROOM_PEER_LEFT':
                this.webrtccStatus.peers[id] = undefined

                const _status12 = {
                    tp: 12,
                    topic: 'Peer has left the room',
                    msg: cmd,
                    data: vals
                }
                this.emit('status', _status12)
                this.doServerLog('status', _status12)

                this.peerHasLeft = true

                return
            case 'ROOM_PEER_MSG':
                const msg = JSON.parse(smsg)
                if (msg.sdp && msg.sdp.type === 'offer') {
                    if (!this.webrtccStatus.peers[id]) {
                        this.webrtccStatus.peers[id] = {}
                    }
                    this.peerConnections[id] = this.answerPeer(id, msg.sdp)
                } else if (msg.sdp && msg.sdp.type === 'answer') {
                    this.webrtccStatus.peers[id].remoteDescription =
                        msg.sdp.sdp.split('\r\n')

                    if (this.isDebug) {
                        const _debug13 = {
                            tp: 13,
                            topic: 'Got SDP answer',
                            msg: cmd,
                            data: vals
                        }
                        this.emit('debug', _debug13)
                    }
                    this.peerConnections[id].setRemoteDescription(msg.sdp)
                } else if (msg.ice) {
                    this.onIncomingICE(this.peerConnections[id], msg.ice)
                } else if (msg.data) {
                    this.emit('data', msg.data)
                } else {
                    if (this.isDebug) {
                        const _debug14 = {
                            tp: 14,
                            topic: 'Unknown incoming JSON',
                            msg: msg,
                            data: vals
                        }
                        this.emit('debug', _debug14)
                    }
                }
                return

            case 'MUTE_PEER':
                const mutes = JSON.parse(smsg)

                if (mutes) {
                    switch (mutes.muteAudioIn) {
                        case 0:
                        case '0':
                        case false:
                        case 'false':
                            this.muteAudioIn(false)
                            break
                        case 1:
                        case '1':
                        case true:
                        case 'true':
                            this.muteAudioIn(true)
                            break

                        default:
                            break
                    }

                    switch (mutes.muteAudioOut) {
                        case 0:
                        case '0':
                        case false:
                        case 'false':
                            this.muteAudioOut(false)
                            break
                        case 1:
                        case '1':
                        case true:
                        case 'true':
                            this.muteAudioOut(true)
                            break

                        default:
                            break
                    }
                }
                return

            case 'SRVLOG_CMD':
                const srvLogCmd = JSON.parse(smsg)
                console.log('SRVLOG_CMD', srvLogCmd)
                break

            case 'SRVLOG_DATA':
                break

            case 'SERVER_TIME':
                const nowTime = parseInt(smsg)

                if (this.connectTime === 0) {
                    this.connectTime = nowTime
                }

                const diffTime = nowTime - this.connectTime

                const srvTime = {
                    nowTime: nowTime,
                    upTime: diffTime
                }

                this.emit('time', srvTime) // in milliseconds

                break

            case 'EJECT_PEER':
                this.emit('eject', smsg)
                break

            case 'ICE_CFG':
                const allIceCfg = JSON.parse(smsg)

                let numIce = allIceCfg.length

                // limit the use of ICE items.
                if (numIce > this.maxNumIce) {
                    numIce = this.maxNumIce
                }

                const iceCfg = allIceCfg.slice(0, numIce)
                if (this.useIceFromSignaling) {
                    this.rtc_configuration.iceServers = iceCfg
                }

                this.logToServer = true
                this.doServerLog('start', this.logToServer)

                break

            case 'ROOM_PEER_LIST':
                // console.log("ROOM_PEER_LIST", vals)

                if (vals) {
                    // we have other browser in the room (vals[2] is first client in room, vals[3] is the second)
                    if (this.isOtherBrowser(vals[2]) || this.isOtherBrowser(vals[3])) {
                        if (
                            window.confirm(
                                'This session already have a remote user, do you want to kick out that user?'
                            )
                        ) {
                            // Kick em!
                            this.connectToRoom()
                        } else {
                            // leave...
                            this.closeWebRTC()
                        }
                    } else {
                        this.connectToRoom()
                    }
                } else {
                    // no one else is here, let's connect to the room
                    this.connectToRoom()
                }

                break

            case 'ROOM_PEER_LIST_EXT':
                console.log('ROOM_PEER_LIST_EXT', JSON.parse(vals[2]))
                break

            default:
                if (this.isDebug) {
                    const _debug15 = {
                        tp: 15,
                        topic: 'Unknown Command',
                        msg: cmd,
                        data: vals
                    }
                    this.emit('debug', _debug15)
                }
        }
    }

    isOtherBrowser(id) {
        return id && id.startsWith('browser-') && !id.startsWith(this.peerIdBase + '#')
    }

    doAutoReconnect() {
        if (this.autoReconnect) {
            if (this.peerHasLeft) {
                // check that the peer did leave the room
                const status1x11 = {
                    tp: 1,
                    topic: 'ConnectionState',
                    msg: 'Reconnecting',
                    data: 11
                }
                this.lastConnectionState = status1x11
                this.emit('status', status1x11)
                this.doServerLog('status', status1x11)

                this.closeConnection() // close the webrtcConnection

                setTimeout(() => {
                    // wait a bit, then do a regular connect (time in ms: this.autoReconnectWait)
                    this.connectWebRTC(this.connectCfg)
                }, this.autoReconnectWait)
            }
        }
    }

    onIceConnectionStateChange = (event) => {
        this.doServerLog('status', `IceConnectionStateChange ${event.target.iceConnectionState}`)
    }

    onIceGatheringStateChange = (event) => {
        this.doServerLog('status', `IceGatheringStateChange ${event.target.iceGatheringState}`)
    }

    onNegotiationNeeded = (event) => {
        this.doServerLog('status', `NegotiationNeeded ${event.target.connectionState} (ice: ${event.target.iceConnectionState})`)
    }

    onConnectionStateChange = (event) => {
        if (
            this.isDebug &&
            event.target &&
            event.target.connectionState &&
            event.target.iceConnectionState &&
            event.target.iceGatheringState &&
            event.target.signalingState
        ) {
            const _debug = {
                tp: 1,
                topic: 'onConnectionStateChange',
                msg: '',
                data: {
                    connectionState: event.target.connectionState,
                    iceConnectionState: event.target.iceConnectionState,
                    iceGatheringState: event.target.iceGatheringState,
                    signalingState: event.target.signalingState
                }
            }

            this.emit('debug', _debug)
        }

        switch (event.target.connectionState) {
            case 'connecting':
                this.statsTimer = setInterval(() => {
                    this.getConnectionStats(false, 'connecting', this.isRoomCreator) // log to screen only
                }, this.statsTimerIntervalMs)

                const status1x1 = {
                    tp: 1,
                    topic: 'ConnectionState',
                    msg: 'Connecting',
                    data: 1
                }

                this.lastConnectionState = status1x1
                this.emit('status', status1x1)
                this.doServerLog('status', status1x1)
                this.getConnectionStats(true, 'connecting', this.isRoomCreator) // send stats onece to logServer
                break

            case 'connected':
                const status1x3 = {
                    tp: 1,
                    topic: 'ConnectionState',
                    msg: 'Connected',
                    data: 3
                }

                this.lastConnectionState = status1x3
                this.emit('status', status1x3)
                this.doServerLog('status', status1x3)
                this.getConnectionStats(true, 'connected', this.isRoomCreator) // send stats onece to logServer
                break

            case 'disconnected':
                const status1x4 = {
                    tp: 1,
                    topic: 'ConnectionState',
                    msg: 'Disconnected',
                    data: 4
                }

                this.lastConnectionState = status1x4
                this.emit('status', status1x4)
                this.doServerLog('status', status1x4)

                this.closeConnection() // close the webrtcConnection

                setTimeout(() => {
                    // wait a bit, then recobbect
                    this.doAutoReconnect()
                }, this.autoReconnectWait)

                break

            case 'failed':
                const status1x5 = {
                    tp: 1,
                    topic: 'ConnectionState',
                    msg: 'Failed',
                    data: 5
                }
                this.lastConnectionState = status1x5
                this.emit('status', status1x5)
                this.doServerLog('status', status1x5)

                this.closeConnection()

                break

            case 'closed':
                const status1x6 = {
                    tp: 1,
                    topic: 'ConnectionState',
                    msg: 'Closed',
                    data: 6
                }
                this.lastConnectionState = status1x6
                this.emit('status', status1x6)
                this.doServerLog('status', status1x6)

                break

            case 'new':
                const status1x10 = {
                    tp: 1,
                    topic: 'ConnectionState',
                    msg: 'New',
                    data: 10
                }
                this.lastConnectionState = status1x10
                this.emit('status', status1x10)
                this.doServerLog('status', status1x10)

                break

            default:
                const status1x99 = {
                    tp: 1,
                    topic: 'ConnectionState',
                    msg: event.target.connectionState,
                    data: 99
                }
                this.lastConnectionState = status1x99
                this.emit('status', status1x99)
                this.doServerLog('status', status1x99)
                break
        }

        this.sendPeerInfo(this.peer_id)
    };

    onServerClose = (event) => {
        const _status = {
            tp: 4,
            topic: 'Disconnected from server',
            msg: '',
            data: event
        }

        this.emit('status', _status)
        this.doServerLog('status', _status)

        const status1x7 = {
            tp: 1,
            topic: 'ConnectionState',
            msg: 'Connection Closed',
            data: 7
        }
        this.lastConnectionState = status1x7
        this.emit('status', status1x7)
        this.doServerLog('status', status1x7)

        this.isInited = false
        this.peerHasLeft = true

        this.closeConnection() // close the webrtcConnection

        setTimeout(() => {
            // wait a bit, then recobbect
            this.doAutoReconnect()
        }, this.autoReconnectWait)
    };

    onServerOpen = (event) => {
        // if the room alreade have an "browser" client, should we ask before kicking out that user or not...
        // if we prompt the new user, then that happens on code row 1020 (ROOM_PEER_LIST)

        if (this.promptNewUser) {
            this.ws_conn.send('ROOM_PEER_LIST ' + this.room_id)
        } else {
            this.connectToRoom()
        }

        const status16 = {
            tp: 16,
            topic: 'ConnectionConfig',
            msg: '',
            data: this.connectCfg
        }
        this.doServerLog('status', status16)
    };

    connectToRoom() {
        const peerJson = {
            title: this.peer_title,
            sessionId: this.room_id,
            inviteId: this.inviteId,
            iceConfig: true
        }

        this.ws_conn.send('HELLO ' + this.peer_id + '|' + JSON.stringify(peerJson))

        // some status messages...
        const _status = {
            tp: 2,
            topic: 'Registering with server',
            msg: this.peer_id,
            data: '' // event
        }

        this.emit('status', _status)
        this.doServerLog('status', _status)

        const status1x2 = {
            tp: 1,
            topic: 'ConnectionState',
            msg: 'Waiting on peer',
            data: 2
        }
        this.lastConnectionState = status1x2
        this.emit('status', status1x2)
        this.doServerLog('status', status1x2)

        this.sendPeerInfo(this.peer_id) // send ws (PEER_INFO)
    }

    doAutoResume() {
        if (this.isInitDebug && this.audioContext) {
            const debug20Data = {
                autoResumeCount: this.autoResumeCount,
                autoResumeMax: this.autoResumeMax,
                audioContextState: this.audioContext.state
            }

            const debug20 = {
                tp: 20,
                topic: 'AutoResume',
                msg: '',
                data: debug20Data
            }
            this.emit('debug', debug20)
        }

        if (this.autoResumeCount < this.autoResumeMax) {
            if (this.audioContext.state === 'suspended') {
                setTimeout(() => {
                    this.resumeAudioContext()

                    this.autoResumeCount++
                    this.doAutoResume() // loop...
                }, 100)
            }
        }

        this.getAudioContextState()
    }

    connectWebRTC = (cfg) => {
        if (this.isInited || this.isStopped) {
            this.closeConnection()

            /* if(this.isInitCfg) {
                  this.initWebRTC(this.initCfg);
                } */
        }

        this.connectCfg = cfg

        this.connectTime = 0

        if (!cfg) {
            const _error9 = {
                tp: 9,
                topic: 'connectWebRTC',
                msg: '',
                data: 'config is missing'
            }

            this.emit('error', _error9)
            this.doServerLog('error', _error9)

            return
        }

        this.localMediaStreamEnable = true // un-mute AudioIn

        if (cfg.hasOwnProperty('sessionId')) {
            this.room_id = cfg.sessionId
        }

        if (cfg.hasOwnProperty('audioIn')) {
            this.selAudioIn = cfg.audioIn
        }

        if (cfg.hasOwnProperty('audioOut')) {
            this.selAudioOut = cfg.audioOut
        }

        if (cfg.hasOwnProperty('sendStereo')) {
            this.localStereo = cfg.sendStereo
        }

        if (cfg.hasOwnProperty('autoGainControl')) {
            this.useAgc = cfg.autoGainControl
        }

        if (cfg.hasOwnProperty('echoCancellation')) {
            this.useEcho = cfg.echoCancellation
        }

        if (cfg.hasOwnProperty('noiseSuppression')) {
            this.useNoice = cfg.noiseSuppression
        }

        if (cfg.hasOwnProperty('receiveStereo')) {
            this.remoteStereo = cfg.receiveStereo
        }

        if (cfg.hasOwnProperty('sendBitRate')) {
            this.localBitRate = cfg.sendBitRate
        }

        if (cfg.hasOwnProperty('ice')) {
            this.rtc_configuration = cfg.ice
            this.useIceFromSignaling = false
        }

        if (cfg.hasOwnProperty('iceTransportPolicy')) {
            this.rtc_configuration.iceTransportPolicy = cfg.iceTransportPolicy
        }

        if (cfg.hasOwnProperty('signal')) {
            this.ws_url = cfg.signal
        }

        if (cfg.hasOwnProperty('debug')) {
            this.isDebug = cfg.debug
        }

        if (cfg.hasOwnProperty('dataChan')) {
            this.dataChan = cfg.dataChan
        }

        if (cfg.hasOwnProperty('confbrowsers')) {
            this.confbrowsers = cfg.confbrowsers
        }

        if (cfg.hasOwnProperty('defaultConstraints')) {
            this.defaultConstraints = cfg.defaultConstraints
        }

        if (cfg.hasOwnProperty('getStats')) {
            this.getStats = cfg.getStats
        }

        if (cfg.hasOwnProperty('autoDisconnect')) {
            this.autoDisconnect = cfg.autoDisconnect
        }

        if (cfg.hasOwnProperty('vumeters')) {
            this.useVUmeters = cfg.vumeters
        }

        if (cfg.hasOwnProperty('autoReconnect')) {
            this.autoReconnect = cfg.autoReconnect
        }

        if (cfg.hasOwnProperty('title')) {
            this.peer_title = cfg.title
        }

        if (cfg.hasOwnProperty('inviteId')) {
            this.inviteId = cfg.inviteId
        }

        if (cfg.hasOwnProperty('maxNumIce')) {
            this.maxNumIce = cfg.maxNumIce
        }

        if (cfg.hasOwnProperty('workletPath')) {
            this.workletPath = cfg.workletPath
        }

        if (cfg.hasOwnProperty('promptNewUser')) {
            this.promptNewUser = cfg.promptNewUser
        }

        if (cfg.hasOwnProperty('allowSDPmod')) {
            this.allowSDPmod = cfg.allowSDPmod
        }

        if (cfg.hasOwnProperty('monitorOnly')) {
            this.isMonitorOnly = cfg.monitorOnly
        }

        this.localAudioPlayer = new window.Audio()

        if (this.localAudioPlayer) {
            // some browsers don't have "setSinkId", like Firefox, Safari and Chrome on Android
            if (typeof this.localAudioPlayer.setSinkId === 'function') {
                if (this.selAudioOut) {
                    this.localAudioPlayer.setSinkId(this.selAudioOut)
                }
            }
        }

        if (this.selAudioIn) {
            this.setConstraints(this.selAudioIn)
        } else {
            this.setConstraints(undefined)
        }

        // this.resumeAudioContext();

        this.createAudioContext()

        this.getLocalMedia()

        this.peer_id = this.peerIdBase + '#' + (++this.peerIdCount)
        this.webrtccStatus.peer = this.peer_id

        this.ws_conn = new window.WebSocket(this.ws_url)
        this.ws_conn.addEventListener('open', this.onServerOpen)
        this.ws_conn.addEventListener('error', this.onServerError)
        this.ws_conn.addEventListener('message', this.onServerMessage)
        this.ws_conn.addEventListener('close', this.onServerClose)

        this.peerHasLeft = false
        this.isInited = true
        this.iceConType = 0

        const status1x0 = {
            tp: 1,
            topic: 'ConnectionState',
            msg: 'Init',
            data: 0
        }
        this.lastConnectionState = status1x0
        this.emit('status', status1x0)
        this.doServerLog('status', status1x0)

        this.mutedCallback()

        return true
    };

    createAudioContext() {
        if (!this.audioContext) {
            try {
                this.audioContext = new (window.AudioContext ||
                    window.webkitAudioContext)()
            } catch (err) {
                console.log(
                    "ERROR - This browser doesn't support the Web Audio API!",
                    err
                )
            }
        }
    }

    getAudioContextState = () => {
        if (this.audioContext) {
            const status1x14 = {
                tp: 14,
                topic: 'AudioContextState',
                msg: '',
                data: this.audioContext.state
            }
            this.emit('status', status1x14)
            this.doServerLog('status', status1x14)
        } else {
            const status1x14b = {
                tp: 14,
                topic: 'AudioContextState',
                msg: '',
                data: null
            }
            this.emit('status', status1x14b)
            this.doServerLog('status', status1x14b)
        }
    };

    resumeAudioContext() {
        if (!this.audioContext) {
            this.createAudioContext()
        }

        if (this.audioContext && this.audioContext.state === 'suspended') {
            this.audioContext
                .resume()
                .then(() => { })
                .catch((err) => {
                    console.log('ERR resumeAudioContext', err)
                })
        }
    }

    closeWebRTC() {
        const status1x9 = {
            tp: 1,
            topic: 'ConnectionState',
            msg: 'Disconnected by user input',
            data: 9
        }
        this.lastConnectionState = status1x9
        this.emit('status', status1x9)
        this.doServerLog('status', status1x9)

        this.closeConnection()
    }

    closeConnection() {
        this.isStopped = true

        if (this.dataChannel) {
            this.dataChannel.close()
        }

        for (let id in this.peerConnections) {
            if (this.peerConnections[id]) {
                this.peerConnections[id].close()
                this.peerConnections[id] = null
            }
        }

        if (this.vuClassRemote) {
            this.vuClassRemote.closeVU()
            this.vuClassRemote = null
        }

        if (this.vuClassLocal) {
            this.vuClassLocal.closeVU()
            this.vuClassLocal = null
        }

        if (this.localAudioPlayer) {
            this.localAudioPlayer.pause()
            this.localAudioPlayer = null
        }

        if (this.audioContext) {
            this.audioContext.close()
            this.audioContext = null
        }

        if (this.ws_conn) {
            this.ws_conn.removeEventListener('error', this.onServerError)
            this.ws_conn.removeEventListener('message', this.onServerMessage)
            this.ws_conn.removeEventListener('close', this.onServerClose)
            this.ws_conn.removeEventListener('open', this.onServerOpen)

            this.ws_conn.close()
            this.ws_conn = null
        }

        this.resetVU()

        const _status = {
            tp: 5,
            topic: 'End Call',
            msg: '',
            data: null
        }

        this.emit('status', _status)
        this.doServerLog('status', _status)

        this.isInited = false

        const status13 = {
            tp: 13,
            topic: 'GetStats',
            msg: '',
            data: this.resetStatsReport()
        }
        this.emit('status', status13)
    }

    muteAudioOut(isMuted) {
        if (this.localAudioPlayer) {
            this.localAudioPlayer.muted = isMuted

            this.mutedCallback()

            setTimeout(() => {
                this.sendPeerInfo(this.peer_id) // send ws
            }, 100)
        }
    }

    muteAudioIn(isMuted) {
        this.localMediaStreamEnable = !isMuted
        if (this.localMediaStream) {
            this.localMediaStream.getTracks().forEach((track) => {
                track.enabled = !isMuted
            })

            this.mutedCallback()

            setTimeout(() => {
                this.sendPeerInfo(this.peer_id) // send ws
            }, 100)
        }
    }

    setConstraints = (audioInputDeviceId) => {
        if (this.useEcho === true) {
            this.localStereo = false // stereo not possible when using echo-cancellation
        }

        if (this.defaultConstraints) {
            this.localStereo = false // stereo is not in default settings

            // use default media constraints
            this.constraints = {
                audio: true,
                video: false
            }
        } else {
            if (audioInputDeviceId) {
                this.constraints = {
                    audio: {
                        deviceId: audioInputDeviceId, // set audio input device
                        autoGainControl: this.useAgc,
                        channelCount: this.localStereo ? 2 : 1, // mono or stereo
                        echoCancellation: this.useEcho,
                        latency: 0,
                        noiseSuppression: this.useNoice,
                        sampleRate: 48000,
                        sampleSize: 16,
                        volume: 1.0
                    },
                    video: false
                }
            } else {
                this.constraints = {
                    audio: {
                        autoGainControl: this.useAgc,
                        channelCount: this.localStereo ? 2 : 1, // mono or stereo
                        echoCancellation: this.useEcho,
                        latency: 0,
                        noiseSuppression: this.useNoice,
                        sampleRate: 48000,
                        sampleSize: 16,
                        volume: 1.0
                    },
                    video: false
                }
            }

            // use user configured media constraints
        }
    };

    setAudioOutput(id) {
        var promise = new Promise(function (resolve, reject) {
            if (!this.isInited) {
                reject(Error('localAudioPlayer not ready'))
            }

            if (this.localAudioPlayer) {
                // some browsers don't have "setSinkId", like Firefor, Safari and Chrome in Android
                if (typeof this.localAudioPlayer.setSinkId === 'function') {
                    this.localAudioPlayer.setSinkId(id)
                }

                this.localAudioPlayer.oncanplay = (event) => {
                    this.localAudioPlayer.play()
                }

                resolve('Audio is being played on ' + this.localAudioPlayer.sinkId)
            } else {
                reject(Error('localAudioPlayer not found'))
            }
        })

        return promise
    }

    generateUUID() {
        var dt = new Date().getTime()
        var uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(
            /[xy]/g,
            function (c) {
                var r = (dt + Math.random() * 16) % 16 | 0
                dt = Math.floor(dt / 16)
                return (c === 'x' ? r : (r & 0x3) | 0x8).toString(16)
            }
        )
        return uuid
    }

    setCookie(cname, cvalue, exdays) {
        if (!exdays) {
            exdays = 365
        }
        var d = new Date()
        d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000)
        var expires = 'expires=' + d.toUTCString()
        document.cookie = cname + '=' + cvalue + ';' + expires + ';path=/'
    }

    mutedCallback() {
        var audioInMuted = false
        var audioOutMuted = false

        if (this.localMediaStream && this.localAudioPlayer) {
            audioInMuted = !this.localMediaStreamEnable
            audioOutMuted = this.localAudioPlayer.muted
        }

        this.emit('audio', {
            audioInMuted: audioInMuted,
            audioOutMuted: audioOutMuted
        }) // callback for this class
    }

    sendPeerInfo(pId) {
        if (!this.ws_conn) {
            return
        }

        var audioInMuted = false
        var audioOutMuted = false

        if (this.localMediaStream && this.localAudioPlayer) {
            audioInMuted = !this.localMediaStreamEnable
            audioOutMuted = this.localAudioPlayer.muted
        }

        var pInfo = {
            audioInMuted: audioInMuted,
            audioOutMuted: audioOutMuted,
            connectionState: this.lastConnectionState.data,
            iceStatus: this.iceStatus
        }

        if (this.ws_conn.readyState === 1) {
            // OPEN
            this.ws_conn.send('PEER_INFO ' + pId + ' ' + JSON.stringify(pInfo))
        }
    }

    // adds 2022-02-17

    getConnectionStats = async (doServerLog, state, roomCreator) => {
        if (!this.remotePeerConnection) {
            return
        }

        this.remotePeerConnection.getStats(null).then((stats) => {
            stats.forEach((report) => {
                const status13 = {
                    tp: 13,
                    topic: 'GetStats',
                    msg: '',
                    state: state,
                    rc: roomCreator,
                    data: this.parseStatsReport(report)
                }

                this.emit('status', status13)
                if (doServerLog) {
                    this.doServerLog('report', status13)
                }
            })
        })
    };

    parseStatsReport(report) {
        if (
            report &&
            report.type === 'local-candidate' &&
            report.transportId.includes('audio')
        ) {
            this.localAudioCandidateId = report.id
            this.localAudioCandidateType = report.candidateType
            this.localAudioProtocol = report.protocol
            this.localAudioNetworkType = report.networkType
            this.localAudioIP = report.ip || report.address || ''
            this.localAudioPort = report.port
        }

        if (
            report &&
            report.type === 'local-candidate' &&
            report.transportId.includes('application')
        ) {
            this.localAppCandidateId = report.id
            this.localAppCandidateType = report.candidateType
            this.localAppProtocol = report.protocol
            this.localAppNetworkType = report.networkType
            this.localAppIP = report.ip || report.address || ''
            this.localAppPort = report.port
        }

        if (
            report &&
            report.type === 'remote-candidate' &&
            report.transportId.includes('audio')
        ) {
            this.remoteAudioCandidateId = report.id
            this.remoteAudioCandidateType = report.candidateType
            this.remoteAudioProtocol = report.protocol
            this.remoteAudioNetworkType = report.networkType
            this.remoteAudioIP = report.ip || report.address || ''
            this.remoteAudioPort = report.port
        }

        if (
            report &&
            report.type === 'remote-candidate' &&
            report.transportId.includes('application')
        ) {
            this.remoteAppCandidateId = report.id
            this.remoteAppCandidateType = report.candidateType
            this.remoteAppProtocol = report.protocol
            this.remoteAppIP = report.ip || report.address || ''
            this.remoteAppPort = report.port
        }

        if (report && report.type === 'codec' && report.id.includes('Outbound')) {
            this.localAudioMimeType = report.mimeType.replace('audio/', '')
            this.localAudioClockRate = report.clockRate
            this.localAudioChannels = report.channels
            this.localAudioPayloadType = report.payloadType
        }

        if (report && report.type === 'codec' && report.id.includes('Inbound')) {
            this.remoteAudioMimeType = report.mimeType.replace('audio/', '')
            this.remoteAudioClockRate = report.clockRate
            this.remoteAudioChannels = report.channels
            this.remoteAudioPayloadType = report.payloadType
        }

        if (report && report.type === 'inbound-rtp' && report.kind === 'audio') {
            this.localInboundJitter = parseFloat(report.jitter).toFixed(3)
            this.localInboundPacketsReceived = report.packetsReceived
            this.localInboundPacketsLost = report.packetsLost
            this.localInboundPacketsDiscarded = report.packetsDiscarded
        }

        if (report && report.type === 'outbound-rtp' && report.kind === 'audio') {
            this.localOutboundPacketsSent = report.packetsSent
            this.localOutboundRetransmittedPacketsSent =
                report.retransmittedPacketsSent
        }

        if (
            report &&
            report.type === 'remote-inbound-rtp' &&
            report.kind === 'audio'
        ) {
            this.remoteInboundJitter = parseFloat(report.jitter).toFixed(3)
            this.remoteInboundPacketsLost = report.packetsLost
            this.remoteInboundRoundTripTime = report.roundTripTime
            this.remoteInboundTotalRoundTripTime = parseFloat(
                report.totalRoundTripTime
            ).toFixed(3)
        }

        if (
            report &&
            report.type === 'remote-outbound-rtp' &&
            report.kind === 'audio'
        ) {
            this.remoteOutboundPacketsSent = report.packetsSent
            this.remoteOutboundTotalRoundTripTime = parseFloat(
                report.totalRoundTripTime
            ).toFixed(3)
        }

        if (report && report.type === 'certificate') {
            this.fingerprintAlgorithm = report.fingerprintAlgorithm
        }

        const telosStats = {
            localAudioCandidateId: this.localAudioCandidateId,
            localAudioCandidateType: this.localAudioCandidateType,
            localAudioNetworkType: this.localAudioNetworkType,
            localAudioProtocol: this.localAudioProtocol,
            localAudioIP: this.localAudioIP,
            localAudioPort: this.localAudioPort,
            localAppCandidateId: this.localAppCandidateId,
            localAppCandidateType: this.localAppCandidateType,
            localAppNetworkType: this.localAppNetworkType,
            localAppProtocol: this.localAppProtocol,
            localAppIP: this.localAppIP,
            localAppPort: this.localAppPort,
            localAudioMimeType: this.localAudioMimeType,
            localAudioClockRate: this.localAudioClockRate,
            localAudioChannels: this.localAudioChannels,
            localAudioPayloadType: this.localAudioPayloadType,
            remoteAppCandidateId: this.remoteAppCandidateId,
            remoteAppCandidateType: this.remoteAppCandidateType,
            remoteAppProtocol: this.remoteAppProtocol,
            remoteAppIP: this.remoteAppIP,
            remoteAppPort: this.remoteAppPort,
            remoteAudioCandidateId: this.remoteAudioCandidateId,
            remoteAudioCandidateType: this.remoteAudioCandidateType,
            remoteAudioProtocol: this.remoteAudioProtocol,
            remoteAudioNetworkType: this.remoteAudioNetworkType,
            remoteAudioIP: this.remoteAudioIP,
            remoteAudioPort: this.remoteAudioPort,
            remoteAudioMimeType: this.remoteAudioMimeType,
            remoteAudioClockRate: this.remoteAudioClockRate,
            remoteAudioChannels: this.remoteAudioChannels,
            remoteAudioPayloadType: this.remoteAudioPayloadType,
            localInboundJitter: this.localInboundJitter,
            localInboundPacketsReceived: this.localInboundPacketsReceived,
            localInboundPacketsLost: this.localInboundPacketsLost,
            localInboundPacketsDiscarded: this.localInboundPacketsDiscarded,
            localOutboundPacketsSent: this.localOutboundPacketsSent,
            localOutboundRetransmittedPacketsSent: this.localOutboundRetransmittedPacketsSent,
            remoteInboundJitter: this.remoteInboundJitter,
            remoteInboundPacketsLost: this.remoteInboundPacketsLost,
            remoteInboundRoundTripTime: this.remoteInboundRoundTripTime,
            remoteInboundTotalRoundTripTime: this.remoteInboundTotalRoundTripTime,
            remoteOutboundPacketsSent: this.remoteOutboundPacketsSent,
            remoteOutboundTotalRoundTripTime: this.remoteOutboundTotalRoundTripTime,
            fingerprintAlgorithm: this.fingerprintAlgorithm
        }

        return telosStats
    }

    resetStatsReport() {
        this.localAudioCandidateId = ''
        this.localAudioCandidateType = ''
        this.localAudioNetworkType = ''
        this.localAudioProtocol = ''
        this.localAudioIP = ''
        this.localAudioPort = ''
        this.localAppCandidateId = ''
        this.localAppCandidateType = ''
        this.localAppNetworkType = ''
        this.localAppProtocol = ''
        this.localAppIP = ''
        this.localAppPort = ''
        this.localAudioMimeType = ''
        this.localAudioClockRate = ''
        this.localAudioChannels = ''
        this.localAudioPayloadType = ''
        this.remoteAppCandidateId = ''
        this.remoteAppCandidateType = ''
        this.remoteAppProtocol = ''
        this.remoteAppIP = ''
        this.remoteAppPort = ''
        this.remoteAudioCandidateId = ''
        this.remoteAudioCandidateType = ''
        this.remoteAudioProtocol = ''
        this.remoteAudioNetworkType = ''
        this.remoteAudioIP = ''
        this.remoteAudioPort = ''
        this.remoteAudioMimeType = ''
        this.remoteAudioClockRate = ''
        this.remoteAudioChannels = ''
        this.remoteAudioPayloadType = ''
        this.localInboundJitter = ''
        this.localInboundPacketsReceived = ''
        this.localInboundPacketsLost = ''
        this.localInboundPacketsDiscarded = ''
        this.localOutboundPacketsSent = ''
        this.localOutboundRetransmittedPacketsSent = ''
        this.remoteInboundJitter = ''
        this.remoteInboundPacketsLost = ''
        this.remoteInboundRoundTripTime = ''
        this.remoteInboundTotalRoundTripTime = ''
        this.remoteOutboundPacketsSent = ''
        this.remoteOutboundTotalRoundTripTime = ''
        this.fingerprintAlgorithm = ''

        const telosStats = {
            localAudioCandidateId: this.localAudioCandidateId,
            localAudioCandidateType: this.localAudioCandidateType,
            localAudioNetworkType: this.localAudioNetworkType,
            localAudioProtocol: this.localAudioProtocol,
            localAudioIP: this.localAudioIP,
            localAudioPort: this.localAudioPort,
            localAppCandidateId: this.localAppCandidateId,
            localAppCandidateType: this.localAppCandidateType,
            localAppNetworkType: this.localAppNetworkType,
            localAppProtocol: this.localAppProtocol,
            localAppIP: this.localAppIP,
            localAppPort: this.localAppPort,
            localAudioMimeType: this.localAudioMimeType,
            localAudioClockRate: this.localAudioClockRate,
            localAudioChannels: this.localAudioChannels,
            localAudioPayloadType: this.localAudioPayloadType,
            remoteAppCandidateId: this.remoteAppCandidateId,
            remoteAppCandidateType: this.remoteAppCandidateType,
            remoteAppProtocol: this.remoteAppProtocol,
            remoteAppIP: this.remoteAppIP,
            remoteAppPort: this.remoteAppPort,
            remoteAudioCandidateId: this.remoteAudioCandidateId,
            remoteAudioCandidateType: this.remoteAudioCandidateType,
            remoteAudioProtocol: this.remoteAudioProtocol,
            remoteAudioNetworkType: this.remoteAudioNetworkType,
            remoteAudioIP: this.remoteAudioIP,
            remoteAudioPort: this.remoteAudioPort,
            remoteAudioMimeType: this.remoteAudioMimeType,
            remoteAudioClockRate: this.remoteAudioClockRate,
            remoteAudioChannels: this.remoteAudioChannels,
            remoteAudioPayloadType: this.remoteAudioPayloadType,
            localInboundJitter: this.localInboundJitter,
            localInboundPacketsReceived: this.localInboundPacketsReceived,
            localInboundPacketsLost: this.localInboundPacketsLost,
            localInboundPacketsDiscarded: this.localInboundPacketsDiscarded,
            localOutboundPacketsSent: this.localOutboundPacketsSent,
            localOutboundRetransmittedPacketsSent: this.localOutboundRetransmittedPacketsSent,
            remoteInboundJitter: this.remoteInboundJitter,
            remoteInboundPacketsLost: this.remoteInboundPacketsLost,
            remoteInboundRoundTripTime: this.remoteInboundRoundTripTime,
            remoteInboundTotalRoundTripTime: this.remoteInboundTotalRoundTripTime,
            remoteOutboundPacketsSent: this.remoteOutboundPacketsSent,
            remoteOutboundTotalRoundTripTime: this.remoteOutboundTotalRoundTripTime,
            fingerprintAlgorithm: this.fingerprintAlgorithm
        }

        return telosStats
    }

    doServerLog = (msgType, data) => {
        if (!this.logToServer && !this.isDebug) return
        if (!data) return
        const srvLog = {
            peer: this.peer_id,
            session: this.room_id,
            data: data
        }
        if (this.isDebug) {
            console.log('SRVLOG_DATA ' + msgType.toUpperCase() + '|' + JSON.stringify(srvLog) + '\n')
        }
        if (this.logToServer && this.ws_conn && this.ws_conn.readyState === 1) {
            this.ws_conn.send('SRVLOG_DATA ' + msgType.toUpperCase() + '|' + JSON.stringify(srvLog) + '\n')
        }
    };
}
/* END of TelosWebRTC class */

/* START MediaDevices */

export class MediaDevices extends SimpleEmitter {
    constructor() {
        super()

        this.devArray = []
        this.hasVideo = false
        this.autoClose = true
        this.locStream = null
    }

    canSelectAudioPlaybackDevice() {
        const audio = document.createElement('audio')
        return typeof audio.setSinkId === 'function'
    }

    async init(useVideo, autoClose) {
        if (useVideo !== undefined) {
            this.hasVideo = useVideo
        }

        if (autoClose !== undefined) {
            this.autoClose = autoClose
        }

        this.close()

        const testConstraints = {
            audio: true,
            video: this.hasVideo
        }

        try {
            this.locStream = await navigator.mediaDevices.getUserMedia(testConstraints)
            let devices = await navigator.mediaDevices.enumerateDevices()

            for (let i = 0; i !== devices.length; ++i) {
                const deviceInfo = devices[i]

                const devItem = {
                    deviceId: deviceInfo.deviceId,
                    kind: deviceInfo.kind,
                    name: deviceInfo.label || deviceInfo.deviceId
                }

                this.devArray.push(devItem)
            }
        } catch (err) {
           console.error("Can't initalize 'getUserMedia' in MediaDevices, are you using HTTPS ?");

           const _error7 = {
            tp: 7,
            topic: 'getUserMedia in MediaDevices',
            msg: 'MediaDevices',
            data: err
            }
            this.emit('error', _error7)
        }

        if (this.devArray) {
            this.devArray.sort((a, b) => a.name.localeCompare(b.name)) // sort the object on "name"
        }

        const videoInDevs = this.devArray.filter(
            (item) => item.kind === 'videoinput'
        )
        const audioInDevs = this.devArray.filter(
            (item) => item.kind === 'audioinput'
        )
        const audioOutDevs = this.devArray.filter(
            (item) => item.kind === 'audiooutput'
        )

        this.emit('videoIn', videoInDevs)
        this.emit('audioIn', audioInDevs)
        this.emit('audioOut', this.canSelectAudioPlaybackDevice() ? audioOutDevs : [])

        this.close()
    }

    close() {
        if (this.locStream) {
            this.locStream.getTracks().forEach((track) => {
                track.stop()
            })
        }
    }
}

/* END MediaDevices */

/* START TestMediaDevice */

export class TestMediaDevice extends SimpleEmitter {
    constructor() {
        super()
        this.devArray = []
        this.locStream = null

        this.audioContext = null

        this.workletPath = './js/'
        this.isRetry = false
        this.audioPlayer = null
        this.oscillator = null
        this.testToneTimer = null
        this.mediaRecorder = null
        this.recordStream = null
        this.recordedChunks = []
        this.testRecordTimer = null
        this.vuClassRecord = null
        this.vuClassPlayback = null
        this.testAudioMode = ''
    }

    init(useVideo) {
        this.devArray = []

        var AudioContext = window.AudioContext || window.webkitAudioContext
        this.audioContext = new AudioContext()
        this.audioPlayer = new window.Audio()

        this.getDevices(useVideo)
    }

    async playTestTone(audioOutputDeviceId, durationMs, freq) {
        if (!durationMs) {
            durationMs = 2000
        }

        if (!freq) {
            freq = 440
        }

        if (this.oscillator) {
            this.stopTestTone()
            clearTimeout(this.testToneTimer)
        }
        this.testAudioMode = 'speaker'
        this.oscillator = this.audioContext.createOscillator()
        this.oscillator.type = 'sine'
        this.oscillator.frequency.setValueAtTime(
            freq,
            this.audioContext.currentTime
        )
        var dest = this.audioContext.createMediaStreamDestination()
        var gainNode = this.audioContext.createGain()
        gainNode.gain.value = 0.4
        this.oscillator.connect(gainNode)
        gainNode.connect(dest)
        this.oscillator.start()

        if (typeof this.audioPlayer.setSinkId === 'function') {
            // some browsers don't have "setSinkId", like Firefox, Safari and Chrome on Android
            await this.audioPlayer.setSinkId(audioOutputDeviceId) // set audio device to play on
        }

        this.audioPlayer.srcObject = dest.stream
        this.audioPlayer.play()
        this.emit('testtone', {
            mode: 1,
            state: 1,
            info: 'playing',
            durationMs: durationMs
        })

        this.testToneTimer = setTimeout(() => {
            this.oscillator.stop()
            this.oscillator.disconnect()
            this.audioPlayer.pause()
            this.audioPlayer.srcObject = null

            this.emit('testtone', {
                mode: 1,
                state: 2,
                info: 'ended',
                durationMs: 0
            })
        }, durationMs)
    }

    modifyGain = (stream, gainValue) => {
        var audioTrack = stream.getAudioTracks()[0]
        stream.removeTrack(audioTrack)
        var src = this.audioContext.createMediaStreamSource(
            new window.MediaStream([audioTrack])
        )
        var dst = this.audioContext.createMediaStreamDestination()
        var gainNode = this.audioContext.createGain()
        gainNode.gain.value = gainValue
        src.connect(gainNode)
        gainNode.connect(dst)
        stream.addTrack(dst.stream.getAudioTracks()[0])
        return stream
    };

    stopTestTone() {
        if (this.audioPlayer) {
            this.oscillator.stop()
            this.oscillator.disconnect()
            this.audioPlayer.pause()
            this.audioPlayer.srcObject = null
        }
    }

    /* TODO */
    // https://stackoverflow.com/questions/41739837/all-mime-types-supported-by-mediarecorder-in-firefox-and-chrome

    getSupportedMimeTypes(media, types, codecs) {
        const isSupported = window.MediaRecorder.isTypeSupported
        const supported = []
        types.forEach((type) => {
            const mimeType = `${media}/${type}`
            codecs.forEach((codec) =>
                [
                    `${mimeType};codecs=${codec}`,
                    `${mimeType};codecs:${codec}`,
                    `${mimeType};codecs=${codec.toUpperCase()}`,
                    `${mimeType};codecs:${codec.toUpperCase()}`
                ].forEach((variation) => {
                    if (isSupported(variation)) {
                        supported.push(variation)
                    }
                })
            )
            if (isSupported(mimeType)) {
                supported.push(mimeType)
            }
        })
        return supported
    }

    async startTestRecording(
        videoConfig,
        audioInputDeviceId,
        bitrate,
        useAgc,
        useEcho,
        useNoice,
        useStereo,
        audioOutputDeviceId,
        durationMs
    ) {
        let videoConstraints = false
        this.testAudioMode = 'mic'
        if (videoConfig === null || videoConfig === undefined) {
            videoConstraints = false
        } else {
            videoConstraints = this.buildVideoConstraints(videoConfig)
        }

        if (audioInputDeviceId) {
            this.recordConstraints = {
                audio: {
                    deviceId: audioInputDeviceId, // set audio input device
                    autoGainControl: useAgc,
                    channelCount: useStereo ? 2 : 1, // mono or stereo (sadly MediaRecorder does not care aboout this...)
                    echoCancellation: useEcho,
                    noiseSuppression: useNoice
                },
                video: videoConstraints
            }
        } else {
            this.recordConstraints = {
                audio: {
                    autoGainControl: useAgc,
                    channelCount: useStereo ? 2 : 1,
                    echoCancellation: useEcho,
                    noiseSuppression: useNoice
                },
                video: videoConstraints
            }
        }

        clearTimeout(this.testRecordTimer)
        this.recordedChunks = []

        this.recordStream = await navigator.mediaDevices.getUserMedia(
            this.recordConstraints
        )

        const mrCfg = {
            audioBitsPerSecond: bitrate * 1000,
            mimeType: 'audio/webm;codecs=opus'
        }

        this.mediaRecorder = new window.MediaRecorder(this.recordStream, mrCfg)

        this.vuClassRecord = new TelosWebRTCvumeter(
            this.recordStream,
            this.audioContext,
            this.workletPath
        )
        this.vuClassRecord.on('meter', (data) => {
            this.emit('recordVu', data)
        })

        this.mediaRecorder.ondataavailable = (event) => {
            this.recordedChunks.push(event.data)
        }

        this.audioPlayer.onplaying = () => {
            if (this.testAudioMode === 'mic') {
                this.emit('recorder', {
                    mode: 2,
                    state: 1,
                    info: 'playing',
                    durationMs: durationMs
                })
            }
        }

        this.audioPlayer.onended = () => {
            if (this.testAudioMode === 'mic') {
                this.emit('recorder', {
                    mode: 2,
                    state: 2,
                    info: 'ended',
                    durationMs: 0
                })
            }
        }

        if (typeof this.audioPlayer.setSinkId === 'function') {
            // some browsers don't have "setSinkId", like Firefox, Safari and Chrome on Android
            await this.audioPlayer.setSinkId(audioOutputDeviceId)
        }

        this.mediaRecorder.onstop = (event) => {
            this.vuClassRecord.closeVU()
            this.recordStream.getTracks().forEach((track) => {
                track.stop()
            })

            let blob
            if (videoConstraints === false) {
                blob = new window.Blob(this.recordedChunks, {
                    type: 'audio/ogg; codecs=opus'
                }) // audio only
            } else {
                blob = new window.Blob(this.recordedChunks, { type: 'video/webm' })
            }

            setTimeout(() => {
                // wait before playback what we just recorded, looks better in UI this way.
                const audioURL = URL.createObjectURL(blob)
                this.audioPlayer.src = audioURL
                this.audioPlayer.play()
            }, 50)
        }

        this.mediaRecorder.start(100) // Start recording, and dump data every 100ms
        this.emit('recorder', {
            mode: 1,
            state: 1,
            info: this.mediaRecorder.state,
            durationMs: durationMs
        })

        this.testRecordTimer = setTimeout(() => {
            this.mediaRecorder.stop()
            this.emit('recorder', {
                mode: 1,
                state: 2,
                info: this.mediaRecorder.state,
                durationMs: 0
            })
        }, durationMs)
    }

    canSelectAudioPlaybackDevice() {
        const audio = document.createElement('audio')
        return typeof audio.setSinkId === 'function'
    }

    async getDevices(useVideo) {
        try {
            const testConstraints = { video: useVideo, audio: true }

            this.locStream = await navigator.mediaDevices.getUserMedia(
                testConstraints
            )

            let devices = await navigator.mediaDevices.enumerateDevices()

            for (let i = 0; i !== devices.length; ++i) {
                const deviceInfo = devices[i]

                const devItem = {
                    deviceId: deviceInfo.deviceId,
                    kind: deviceInfo.kind,
                    name: deviceInfo.label || `Audio Input [${deviceInfo.deviceId ? deviceInfo.deviceId.substring(0, 32) : i}]`
                }

                this.devArray.push(devItem)
            }

            if (this.devArray) {
                this.devArray.sort((a, b) => a.name.localeCompare(b.name)) // sort the object on "name"
            }

            const videoInDevs = this.devArray.filter(
                (item) => item.kind === 'videoinput'
            )
            const audioInDevs = this.devArray.filter(
                (item) => item.kind === 'audioinput'
            )
            const audioOutDevs = this.devArray.filter(
                (item) => item.kind === 'audiooutput'
            )

            this.emit('videoIn', videoInDevs)
            this.emit('audioIn', audioInDevs)
            this.emit('audioOut', this.canSelectAudioPlaybackDevice() ? audioOutDevs : [])

            this.close(false)
        } catch (err) {
            console.log('err', err)

            // if we get an error, then try again but without video in Constraints. The default camera could be in use and locked by other software.
            if (!this.isRetry) {
                // only retry once!
                this.isRetry = true
                this.getDevices(false)
            }
        }
    }

    buildVideoConstraints(videoCfg) {
        let newConstraints

        if (videoCfg && videoCfg.videoResolution && videoCfg.videoDevice) {
            switch (videoCfg.videoResolution) {
                case 'default':
                    newConstraints = {
                        deviceId: { exact: videoCfg.videoDevice }
                    }
                    break

                case '360p':
                    newConstraints = {
                        deviceId: { exact: videoCfg.videoDevice },
                        width: { ideal: 640 },
                        height: { ideal: 360 }
                    }
                    break

                case '480p':
                    newConstraints = {
                        deviceId: { exact: videoCfg.videoDevice },
                        width: { ideal: 854 },
                        height: { ideal: 480 }
                    }
                    break

                case '720p':
                    newConstraints = {
                        deviceId: { exact: videoCfg.videoDevice },
                        width: { ideal: 1280 },
                        height: { ideal: 720 }
                    }
                    break

                case '1080p':
                    newConstraints = {
                        deviceId: { exact: videoCfg.videoDevice },
                        width: { ideal: 1920 },
                        height: { ideal: 1080 }
                    }
                    break

                case 'QVGA': // 4:3
                    newConstraints = {
                        deviceId: { exact: videoCfg.videoDevice },
                        width: { ideal: 320 },
                        height: { ideal: 240 }
                    }
                    break

                case 'VGA': // 4:3
                    newConstraints = {
                        deviceId: { exact: videoCfg.videoDevice },
                        width: { ideal: 640 },
                        height: { ideal: 480 }
                    }
                    break

                case 'XGA': // 4:3
                    newConstraints = {
                        deviceId: { exact: videoCfg.videoDevice },
                        width: { ideal: 1024 },
                        height: { ideal: 768 }
                    }
                    break

                case 'SXGA': // 4:3
                    newConstraints = {
                        deviceId: { exact: videoCfg.videoDevice },
                        width: { ideal: 1280 },
                        height: { ideal: 960 }
                    }
                    break

                case 'UXGA': // 4:3
                    newConstraints = {
                        deviceId: { exact: videoCfg.videoDevice },
                        width: { ideal: 1600 },
                        height: { ideal: 1200 }
                    }
                    break

                default:
                    newConstraints = true
                    break
            }
        }

        return newConstraints
    }

    async start(videoConfig) {
        this.close(false)

        let audioConstraints = {
            deviceId: videoConfig.audioIn,
            autoGainControl: false,
            channelCount: 2,
            echoCancellation: false,
            noiseSuppression: false
        }

        const previewConstraints = {
            video: this.buildVideoConstraints(videoConfig),
            audio: audioConstraints
        }

        // console.log("previewConstraints",previewConstraints);

        try {
            this.locStream = await navigator.mediaDevices.getUserMedia(
                previewConstraints
            )

            this.getInfo()

            const testVideo = document.getElementById(videoConfig.videoElement)
            if (testVideo) {
                this.vuClassLocal = new TelosWebRTCvumeter(
                    this.locStream,
                    this.audioContext,
                    this.workletPath
                )
                this.vuClassLocal.on('meter', (data) => {
                    this.emit('localVu', data)
                })

                testVideo.srcObject = this.locStream
            }

            return {
                hasError: false,
                status: 'OK'
            }
        } catch (err) {
            return {
                hasError: true,
                status: err.message
            }
        }
    }

    applyVideoConstraints(videoConfig) {
        if (this.locStream) {
            const track = this.locStream.getVideoTracks()[0]
            let constraints = track.getConstraints()

            const ctemp = this.buildVideoConstraints(videoConfig)

            constraints.height = ctemp.height
            constraints.width = ctemp.width
            console.log('constraints2', ctemp)

            track
                .applyConstraints(ctemp)
                .then(() => {
                    // Do something with the track such as using the Image Capture API.
                    console.log('applyConstraints - OK!')
                })
                .catch((err) => {
                    // The constraints could not be satisfied by the available devices.
                    console.log('applyConstraints - ERR:', err.message)
                })
        }
    }

    getInfo() {
        this.locStream.getVideoTracks().forEach((track) => {
            if (track) {
                try {
                    const capabilities = track.getCapabilities()
                    this.emit('videoCapabilities', capabilities)
                } catch (err) {
                    console.log('track.getCapabilities - ERR:', err.message)
                }

                try {
                    const settings = track.getSettings()
                    this.emit('videoSetings', settings)
                } catch (err) {
                    console.log('track.getSettings - ERR:', err.message)
                }
            }
        })
    }

    close(closeAudioContext) {
        if (closeAudioContext === undefined) {
            closeAudioContext = true
        }

        if (this.vuClassRecord) {
            this.vuClassRecord.closeVU()
            const clearVU = {
                rmsL: 0,
                rmsR: 0,
                dbL: -50,
                dbR: -50,
                percL: 0,
                percR: 0
            }
            this.emit('recordVu', clearVU)
        }

        if (this.vuClassPlayback) {
            this.vuClassPlayback.closeVU()
            const clearVU = {
                rmsL: 0,
                rmsR: 0,
                dbL: -50,
                dbR: -50,
                percL: 0,
                percR: 0
            }
            this.emit('playbackVu', clearVU)
        }

        if (this.locStream) {
            this.locStream.getTracks().forEach((track) => {
                track.stop()
            })
        }

        if (closeAudioContext && this.audioContext) {
            this.audioContext.close()
            console.log('AudioContext closed')

            this.recordedChunks = []
        }
    }
}

/* END TestMediaDevice */

function SimpleEmitter() {
    if (!(this instanceof SimpleEmitter)) { throw new TypeError('Emitter is not a function.') }

    let handlers = []

    this.addEventListener = (type, fn) => {
        handlers.push([type, fn])
    }

    this.removeEventListener = (type, fn = true) => {
        handlers = handlers.filter(
            (handler) =>
                !(handler[0] === type && (fn === true ? true : handler[1] === fn))
        )
    }

    this.dispatchEvent = (type, data) => {
        handlers
            .filter((handler) =>
                new RegExp('^' + handler[0].split('*').join('.*') + '$').test(type)
            )
            .forEach((handler) => handler[1](data, type))
    }

    this.clearEventListeners = () => {
        handlers = []
    }

    this.getEventListeners = (type) => {
        if (!type) {
            return handlers
        }

        let fns = []
        handlers
            .filter((handler) => handler[0] === type)
            .forEach((handler) => fns.push(handler[1]))

        return fns
    }

    this.on = (type, fn) => {
        this.addEventListener(type, fn)
        return this /* chain */
    }

    this.off = (type, fn) => {
        this.removeEventListener(type, fn)
        return this /* chain */
    }

    this.emit = (type, data) => {
        this.dispatchEvent(type, data)
        return this /* chain */
    }

    this.clear = (type) => {
        this.clearEventListeners(type)
        return this
    }

    this.list = (type) => this.getEventListeners(type)
}

  // Not needed, the class is used internaly (private)
  // if (typeof module !== 'undefined' /* && !!module.exports*/ ) {
  //  module.exports = SimpleEmitter;
  // }

/* END - SimpleEmitter */
