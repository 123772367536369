import React, { Component } from 'react';
import { 
  pushNotification,  
  confirm, 
  alert,
  prompt
} from "@telosalliance/ui-core-framework";

import {
    Panel,
    Table,
    IconButton,
    Modal,
    Button,
    Select,
    InfoTooltip
  } from "@telosalliance/ui-core";


  import axios from "axios";

  import DeleteIcon from '@material-ui/icons/Delete';
  import EditIcon from '@material-ui/icons/Edit';
  import AddBoxIcon from '@material-ui/icons/AddBox';
  import WarningIcon from '@material-ui/icons/Warning';
  //import CachedIcon from '@material-ui/icons/Cached';

  import { 
      ArrowDropUp,
      ArrowDropDown,
  } from '@material-ui/icons';

  import Divider from '@material-ui/core/Divider';
  import Grid from '@material-ui/core/Grid';
  import MuiAlert from '@material-ui/lab/Alert';
  import SaveIcon from '@material-ui/icons/Save';
  import MailOutlineIcon from '@material-ui/icons/MailOutline';


class Settings extends Component {

  constructor(props) {
    super(props);

    this.state = {
        //isLoggedIn : props.isLoggedIn,
        isLoaded:false,
        selectedIceId: 1, //always use id 1 (we only have one icesetup)
        iceConfigs:[],
        iceConfigItems:[],
        editModalOpen: false,
        allowNew: false,
        deleteDialogOpen:false,
        deleteSelectedId: 0,
        currentIceItem: {},
        isEditMode: false,
        iceType:0,
        axiosConfig:"",
        isUnAuthorized:false,
        agentGroupGUID:'',
        userGUID:'',
        userRole:'',
        userTimeZone:'',

        emailServerType: "smtp",
        emailSmtp : 
            {
              server: "",
              port: 587,
              user: "",
              password: "",
              defaultFromEmail: "",
              defaultFromName: "Telos LiveNet",
              defaultSubject: "LiveNet Invite",
          },

        emailMailJet: {
          defaultFromEmail: "",
          defaultFromName: "Telos LiveNet",
          defaultSubject: "LiveNet Invite",
          apikey:"",
          apisecret: ""
        },

        emailMailGun: {
          defaultFromEmail: "",
          defaultFromName: "Telos LiveNet",
          defaultSubject: "LiveNet Invite",
          apikey:"",
          apidomain: ""
        },

        emailSendGrid: {
          defaultFromEmail: "",
          defaultFromName: "Telos LiveNet",
          defaultSubject: "LiveNet Invite",
          apikey:""
        },

        miscConfig: {
          serverUrl: "",
          invitePasswordLength: 8,
          inviteDuration: 6,
          inviteDurationUnit: "hours",
          inviteEmailTitle: ""
        },
        firstsConfig: {},

        defaultBaseUrl:"",
        hasAppSettings: true,
        isIceLoaded: false,
        
        
        
    };

    // using this method since state is async, and can't be read direct after set.
    this.tokenHeader = "";

    this.emailServerTypes = [
        {id: "none", label: "Disabled"},
        {id: "smtp", label: "SMTP"},
        {id: "mailjet", label: "Mailjet"},
        {id: "mailgun", label: "Mailgun"},
        {id: "sendgrid", label: "SendGrid"},
      ];

  }

  getToken() {
    // get "bakedToken" from session (session gets populated via login, remove __STRING__ which is added by Daren's "useSessionStorage")
    if(sessionStorage.getItem('tls-beacon-usertoken')) {
      const bakedToken = sessionStorage.getItem('tls-beacon-usertoken').replace("__STRING__", "");
      const bakedJson = JSON.parse(atob(bakedToken)); //decode base64

      //console.log("bakedJson",bakedJson)

      this.tokenHeader = { headers: { 'Authorization': 'Bearer ' + bakedJson.token, 'Content-Type': 'application/json' } }

      // use the callback in setState & it's callback, this so we have the values in the state before we use them.
      this.setState({ 
        agentGroupGUID: bakedJson.agentGroupGuid, 
        userGUID: bakedJson.userGuid, 
        userRole: bakedJson.role,
        userTimeZone: bakedJson.timeZone,
        defaultBaseUrl: window.location.origin + bakedJson.baseUrl
      }, () => {
        // load local stuff
        this.loadIceData(1);
        this.loadIceItems(1);
  
        this.loadAppSettings();

      });
      
    } else {
      this.props.setAuthError("noToken");
    }
  }  


  componentDidMount() {
 
    this.getToken(); 
    
  }

  /* TODO make Global */
  redirectToLogin = () => {
    const { history } = this.props;
    if (history) {
      sessionStorage.setItem("tls-beacon-loggedin", false);
      history.push("./login");
    }
  };

  catchUnAuthorized(err, title) {
    if(!err) return;

    if(err.response) {
       // 401 = Unauthorized, 403 = Forbidden
      if (err.response.status === 401 || err.response.status === 403) {
        this.setState({isUnAuthorized:true})
        this.redirectToLogin();
      } else {
        pushNotification("ERROR (" + title + "): " + err.message);
        console.log("ERROR (" + title + "): ", err.message);
      }
    }
  }


  loadIceData(id, cfg) {
      axios.get("./api/ice", this.tokenHeader).then((res) => {

        this.setState({
          iceConfigs: res.data,
          isLoaded:true,
        });

        if(id) { // did we provide an id, if so select that
          document.getElementById("cmdIceConfig").value = id;
        }

      }).catch((err) => {
        this.catchUnAuthorized(err,"loadIceData");
      });

  }

  loadIceItems(selId){

      axios.get("./api/iceitem?id=" + selId, this.tokenHeader).then((res) => {
          const configs = res.data;

          this.setState({
              iceConfigItems: configs,
              selectedIceId: selId,
              isLoaded:true,
              isIceLoaded:true,
          });

          if(selId>0) {
              this.setState({
                  allowNew: true,
              }); 
          } else {
              this.setState({
                  allowNew: false,
              }); 
          }

        }).catch((err) => {
          this.catchUnAuthorized(err,"loadIceItems");
        });

    }

    handleNewIceItem = (iceType) => {

      // only alow one STUN server
      if(iceType === 1){
       
        var numStuns = this.state.iceConfigItems.filter(f => f.iceType===1).length;

        if(numStuns>0){
          alert("You can only have one STUN server");
          return;
        }

      }

      this.setState({ editModalOpen: true, isEditMode:false, iceType: iceType, currentIceItem:{} });

    };

    handleEditBtn = (id) => {
      
      let items = this.state.iceConfigItems; 
      let item = items.filter(f => f.iceServerItemId===id)[0]; 
      item.iceId = id;

      this.setState({ editModalOpen: true, isEditMode:true, currentIceItem: item, iceType: item.iceType});
    };

    handleDeleteIceItem = (id) => {
      axios.delete("./api/iceitem?id=" + id, this.tokenHeader).then((res) => {
          this.loadIceItems(this.state.selectedIceId); 

      }).catch((err) => {
        this.catchUnAuthorized(err,"handleDeleteIceItem");
      });
      
    };

    handleMoveUpBtn= (id, sortOrder) => {
        const data = {
          id: id,
          newPos: parseInt(sortOrder-150)
        }
        //console.log("handleMoveUpBtn", id, data);

        axios.post("./api/sorticeitem", data, this.tokenHeader).then((res) => {
            this.loadIceItems(this.state.selectedIceId); 
        }).catch((err) => {
          this.catchUnAuthorized(err,"handleMoveUpBtn");
        });
    };

    handleMoveDnBtn= (id, sortOrder) => {
      const data = {
        id: id,
        newPos: parseInt(sortOrder+150)
      }
      //console.log("handleMoveDnBtn", id, data);

      axios.post("./api/sorticeitem", data, this.tokenHeader).then((res) => {
            this.loadIceItems(this.state.selectedIceId); 
        }).catch((err) => {
          this.catchUnAuthorized(err,"handleMoveDnBtn");
        });

    };

    handleCloseDialog = () => {
        this.setState({ deleteDialogOpen: false });
    };



    handleIceItemSave =() => {
       
      const iceUrl = this.state.currentIceItem.iceUrl.trim();
      let iceUser = "";
      let icePwd = "";
      
      //check that STUN url begins correct
        if(this.state.iceType === 1) {
            if(iceUrl.toLowerCase().startsWith("stun:") || iceUrl.toLowerCase().startsWith("stuns:")) {
                //valid
            } else {
              alert(<>STUN server url must begin with <b>stun:</b><br/>Example: <b>stun:myserver.net</b></>, {
                variant: "warning",
              });
              return;
            }
        }

        //check that TURN url begins correct
        if(this.state.iceType === 2) {
          if(iceUrl.toLowerCase().startsWith("turn:") || iceUrl.toLowerCase().startsWith("turns:")) {
            //valid
          } else {
            alert(<>TURN server url must begin with <b>turn:</b><br/>Example: <b>turn:myserver.net</b></>, {
              variant: "warning",
            });
            return;
          }
        }

        //check username so it dosen't has 'not allowed' characters 
        if(this.state.currentIceItem.user) {
          iceUser = this.state.currentIceItem.user.trim();
          const pattern = /^[a-zA-Z0-9%._\\/+~#=\-_.]+$/;
          const checkInput = iceUser.match(pattern);

          if(!checkInput) {
            alert(<>Invalid character in User<br/><br/>Only use: <b>A-Z a-z 0-9 + - ~ # = /</b></>, {
              variant: "warning",
            });
            return;
          }
        }

        //check password so it dosen't has 'not allowed' characters
        if(this.state.currentIceItem.pass) {
          icePwd = this.state.currentIceItem.pass.trim();
          const pattern = /^[a-zA-Z0-9%._\\/+~#=\-_.]+$/;
          const checkInput = icePwd.match(pattern);

          if(!checkInput) {
            alert(<>Invalid character in Password<br/><br/>Only use: <b>A-Z a-z 0-9 + - ~ # = /</b></>, {
              variant: "warning",
            });
            return;
          }
        }

        if(this.state.isEditMode) {
            // update current IceItem
            axios
            .put("./api/iceitem", this.state.currentIceItem, this.tokenHeader)
            .then((res) => {
              //console.log("RECEIVED: ", res.data);
              this.loadIceItems(this.state.selectedIceId); 
              this.setState({ editModalOpen: false });
            })
            .catch((err) => {
              this.catchUnAuthorized(err,"handleIceItemSave (put)");
            });


        } else {

            // Add New IceItem
            const postData = {
                iceId: parseInt(this.state.selectedIceId),
                iceType: this.state.iceType,
                iceUrl: iceUrl,
                user: iceUser,
                pass: icePwd,
            }

            axios
            .post("./api/iceitem", postData, this.tokenHeader)
            .then((res) => {
                //console.log("RECEIVED: ", res.data);
                this.loadIceItems(this.state.selectedIceId); 
                this.setState({ editModalOpen: false });
            })
            .catch((err) => {
              this.catchUnAuthorized(err,"handleIceItemSave (post)");
            });

        }

        
    }

    handleIceItem =  (event) => {
        // console.log(event.target.id, event.target.value);
        const dataType = event.target.getAttribute("data-type");
        const item = event.target.id;
        const data = event.target.value;
    
        const updatedItems = { ...this.state.currentIceItem };
        if (dataType) {
          switch(dataType){
            case "int":
              updatedItems[item] = parseInt(data);  
            break;
            case "double":
              updatedItems[item] = parseFloat(data);  
            break;
            case "bool":
              updatedItems[item] = JSON.parse(data);  
            break;
            case "string":
              updatedItems[item] = data;  
            break;
            default:
              updatedItems[item] = data;  
              break;
          }
        } else {
          updatedItems[item] = data;  
        }

        this.setState({ currentIceItem: updatedItems });
    }


    handleAddIceConfig = (newTitle) => {
        console.log("handleAddIceConfig", newTitle)
        
        if(newTitle !== false) {
          
          const data = {
              title: newTitle,
              description: ''
          }

          axios.post("./api/ice",data, this.tokenHeader).then((res) => {
             
             this.loadIceData(res.data.id);
             this.loadIceItems(res.data.id); 

              pushNotification("Ice Config added: " + newTitle , {
                variant: "success",
              });
    

          }).catch((err) => {
            this.catchUnAuthorized(err,"handleAddIceConfig");
          });

        }
       
    };
    
    handleDeleteIceConfig = (event) => {
      
      axios.delete("./api/ice?id=" + this.state.selectedIceId, this.tokenHeader).then((res) => {
          this.loadIceData(); 

          this.setState({
            iceConfigItems: [],
            selectedIceId: 0,
            allowNew: false,
        });

      

      }).catch((err) => {
        this.catchUnAuthorized(err,"handleDeleteIceConfig");
      });

      //

    };

    handleSetDefaultStun = () => {
      
      const items = { ...this.state.currentIceItem };
      items['iceUrl'] = 'stun:stun.l.google.com:19302';
      items['user'] = '';
      items['pass'] = '';

      this.setState({
        currentIceItem: items
      }, () =>{
        this.handleIceItemSave()
      })

     // this.setState({ currentIceItem: items});

      
    };

    renderModalEdit() {

        let editModeTitle = "Add New";
        if(this.state.isEditMode) {editModeTitle = "Edit";}

       
        let saveDisabled = true;
        let iceInfoText = "";
        // for TURN check that we enterd url/user/pwd
        if(this.state.iceType === 2) {
          if(this.state.currentIceItem.iceUrl && this.state.currentIceItem.user && this.state.currentIceItem.pass) {
            saveDisabled = false;
          } else {
            saveDisabled = true;
          }
        } else {
           // for STUN check that we enterd url
          if(this.state.currentIceItem.iceUrl) {
            saveDisabled = false;
          }

          //iceInfoText = "STUN servers usually don't require user and password, leave those fields blank.";
        }

        let iceButtons = [
          <button key="uic_btnCancel" type="button" className="uic-btn btn-grey" onClick={() => this.setState({ editModalOpen: false })} >Cancel</button>,
          <button key="uic_btnAdd" disabled={saveDisabled} type="button" className="uic-btn btn-primary" onClick={() => this.handleIceItemSave()} color="primary">Save</button>,
        ]

        if(this.state.iceType === 1) {
          iceButtons = [
            <button key="uic_btnCancel" type="button" className="uic-btn btn-grey" onClick={() => this.setState({ editModalOpen: false })} >Cancel</button>,
            <button key="uic_btnDefaultStun" type="button" className="uic-btn btn-grey" onClick={() => this.handleSetDefaultStun()}   >Use default STUN</button>,
            <button key="uic_btnAdd" disabled={saveDisabled} type="button" className="uic-btn btn-primary" onClick={() => this.handleIceItemSave()} color="primary">Save</button>,
          ]
        }

        return (
          <Modal
              open={this.state.editModalOpen}
              className="session-inviteModal"
              onEscapeKeyDown={() => this.setState({ editModalOpen: false })}
              buttons={iceButtons}
            >
              <h2 className="panel-subSection-header">{editModeTitle}</h2>
             
              <Table
                alignLabelsLeft
                yPad={10}
                rows={[
                  ["URL", 
                  <input 
                    className="uic-input inpWith25vw" 
                    id="iceUrl" 
                    placeholder="URL" 
                    name="iceUrl" 
                    autoComplete="off" 
                    type="text"
                    required 
                    defaultValue={this.state.currentIceItem.iceUrl}
                    onChange={this.handleIceItem} 
                    data-type="string" 
                  />],
                  ["User", 
                  <input 
                    className="uic-input inpWith25vw" 
                    id="user" 
                    placeholder="User" 
                    name="user" 
                    autoComplete="off" 
                    type="text"
                    defaultValue={this.state.currentIceItem.user}
                    onChange={this.handleIceItem} 
                    data-type="string" 
                  />],
                  ["Password", 
                  <input 
                    className="uic-input inpWith25vw" 
                    id="pass" 
                    placeholder="Password" 
                    name="pass" 
                    autoComplete="off" 
                    type="password" 
                    defaultValue={this.state.currentIceItem.pass}
                    onChange={this.handleIceItem} 
                    data-type="string" 
                  />]
                  
                ]}
              />
              <span style={{fontSize:"13px", color:"#c0c0c0"}}>{iceInfoText}</span>
            </Modal>
        )
    }

    
    updateGlobalSignaling = () => {

    }


    /* Email */

    handleEmailItemSmtp =  (event) => {
        // console.log(event.target.id, event.target.value);
        const dataType = event.target.getAttribute("data-type");
        const item = event.target.id;
        const data = event.target.value;
    
        const updatedItems = { ...this.state.emailSmtp };
        if (dataType) {
          switch(dataType){
            case "int":
              updatedItems[item] = parseInt(data);  
            break;
            case "double":
              updatedItems[item] = parseFloat(data);  
            break;
            case "bool":
              updatedItems[item] = JSON.parse(data);  
            break;
            case "string":
              updatedItems[item] = data;  
            break;
            default:
              updatedItems[item] = data;  
              break;
          }
        } else {
          updatedItems[item] = data;  
        }

        this.setState({ emailSmtp: updatedItems });
    }

    handleEmailItemMailJet =  (event) => {
      // console.log(event.target.id, event.target.value);
      const dataType = event.target.getAttribute("data-type");
      const item = event.target.id;
      const data = event.target.value;
  
      const updatedItems = { ...this.state.emailMailJet };
      if (dataType) {
        switch(dataType){
          case "int":
            updatedItems[item] = parseInt(data);  
          break;
          case "double":
            updatedItems[item] = parseFloat(data);  
          break;
          case "bool":
            updatedItems[item] = JSON.parse(data);  
          break;
          case "string":
            updatedItems[item] = data;  
          break;
          default:
            updatedItems[item] = data;  
            break;
        }
      } else {
        updatedItems[item] = data;  
      }

      this.setState({ emailMailJet: updatedItems });
  }

  handleEmailItemMailGun =  (event) => {
    // console.log(event.target.id, event.target.value);
    const dataType = event.target.getAttribute("data-type");
    const item = event.target.id;
    const data = event.target.value;

    const updatedItems = { ...this.state.emailMailGun};
    if (dataType) {
      switch(dataType){
        case "int":
          updatedItems[item] = parseInt(data);  
        break;
        case "double":
          updatedItems[item] = parseFloat(data);  
        break;
        case "bool":
          updatedItems[item] = JSON.parse(data);  
        break;
        case "string":
          updatedItems[item] = data;  
        break;
        default:
          updatedItems[item] = data;  
          break;
      }
    } else {
      updatedItems[item] = data;  
    }

    this.setState({ emailMailGun: updatedItems });
}

handleEmailItemSendGrid =  (event) => {
  // console.log(event.target.id, event.target.value);
  const dataType = event.target.getAttribute("data-type");
  const item = event.target.id;
  const data = event.target.value;

  const updatedItems = { ...this.state.emailSendGrid };
  if (dataType) {
    switch(dataType){
      case "int":
        updatedItems[item] = parseInt(data);  
      break;
      case "double":
        updatedItems[item] = parseFloat(data);  
      break;
      case "bool":
        updatedItems[item] = JSON.parse(data);  
      break;
      case "string":
        updatedItems[item] = data;  
      break;
      default:
        updatedItems[item] = data;  
        break;
    }
  } else {
    updatedItems[item] = data;  
  }

  this.setState({ emailSendGrid: updatedItems });
}

handleMiscConfig =  (event) => {
  // console.log(event.target.id, event.target.value);
  const dataType = event.target.getAttribute("data-type");
  const item = event.target.id;
  const data = event.target.value;

  const updatedItems = { ...this.state.miscConfig };
  if (dataType) {
    switch(dataType){
      case "int":
        updatedItems[item] = parseInt(data);  
      break;
      case "double":
        updatedItems[item] = parseFloat(data);  
      break;
      case "bool":
        updatedItems[item] = JSON.parse(data);  
      break;
      case "string":
        updatedItems[item] = data;  
      break;
      default:
        updatedItems[item] = data;  
        break;
    }
  } else {
    updatedItems[item] = data;  
  }

    this.setState({ miscConfig: updatedItems });
  }


  handleMiscDurationUnit = (val) =>{
    const updatedItems = { ...this.state.miscConfig };
    updatedItems["inviteDurationUnit"] = val;
    this.setState({ miscConfig: updatedItems });
}

  loadAppSettings = () => {
       
      axios.get("./api/getAppSettings", this.tokenHeader).then((res) => {
      
        const settings = res.data;

       // console.log("loadAppSettings",settings);

        if(settings){

            this.setState({
              emailServerType: settings.emailServerType,
              emailSmtp: settings.emailSmtp,
              emailMailJet: settings.emailMailJet,
              emailMailGun: settings.emailMailGun,
              emailSendGrid: settings.emailSendGrid,
              miscConfig: settings.misc,
              firstsConfig: settings.firsts,

              hasAppSettings: true
            }, () =>{
              
              if(!this.state.miscConfig.serverUrl) {
                const miscData = this.state.miscConfig;
                miscData.serverUrl = this.state.defaultBaseUrl;
                this.setState({miscConfig: miscData})
              }
            })
        }

      }).catch((err) => {

        if(!this.state.miscConfig.serverUrl) {
          const miscData = this.state.miscConfig;
          miscData.serverUrl = this.state.defaultBaseUrl;
          this.setState({miscConfig: miscData})
        }

        //console.log("err", err);

        if(err.response) {
          // 400 = BadRequest
          if (err.response.status === 400) {
            this.setState({hasAppSettings:false})
            //pushNotification("Please configure 'App Setup'", { variant: "warning" });
          } else {
            
            this.catchUnAuthorized(err,"loadAppSettings");
          }
        }

        
      });

    }


    saveAppSettings = (showNote) => {

      const firstsData = this.state.firstsConfig;
      firstsData.isFirstMailConfig = false; // set that we have saved email.
      firstsData.isFirstMiscConfig = false; // set that we have saved misc.
      this.setState({firstsConfig: firstsData})

      this.state.emailSmtp.port = parseInt(this.state.emailSmtp.port);

      const data = {
        emailServerType: this.state.emailServerType,
        emailSmtp: this.state.emailSmtp,
        emailMailJet: this.state.emailMailJet,
        emailMailGun : this.state.emailMailGun,
        emailSendGrid: this.state.emailSendGrid,
        misc : this.state.miscConfig,
        firsts : firstsData,
        sendToEmail: "",
      }

      console.log("saveAppSettings",data);

      axios.post("./api/saveAppSettings", data, this.tokenHeader).then((res) => {
        
        this.setState({hasAppSettings: true});
        if(showNote) {
          pushNotification("App Setup was saved!", {variant: "success"});
        }

      }).catch((err) => {
        this.catchUnAuthorized(err,"saveAppSettings");
      });

    }




    handleEmailServerType = (id) =>{
        this.setState({emailServerType: id});

        if(id === 'none') {

        }
    }

    renderSendTestEmail() {

      if(this.state.emailServerType === "none"){
        return null;
      }

      return (
        <>
          <button 
            style={{marginLeft:"10px"}}
            className="uic-btn"
            disabled={!this.state.hasAppSettings}
            onClick={async (event) => {
              event.preventDefault();

                  const data = await prompt(
                    <>
                      Please enter an send to email address
                    </>,
                    {
                      autoSelect: true,
                    }
                  );

                  if(!data) return;
                  this.sendTestEmail(data)
                

            }}
          ><MailOutlineIcon /> Send Test Email</button>

          <Divider />
        </>
      )
    }

    renderSmtpSettings() {

        if(this.state.emailServerType !== "smtp"){
          return null;
        }

        return (
              <>
                <Table
                  alignLabelsLeft
                  yPad={10}
                  xPad={20}
                  rows={[
                  ["SMTP Address", 
                  <input 
                      className="uic-input inpWith25vw" 
                      id="server" 
                      placeholder="Address" 
                      autoComplete="off" 
                      type="text"
                      required 
                      defaultValue={this.state.emailSmtp.server}
                      onChange={this.handleEmailItemSmtp} 
                      data-type="string" 
                  />],
                  ["SMTP Port", 
                  <input 
                      className="uic-input inpWith100" 
                      id="port" 
                      placeholder="Port" 
                      autoComplete="off" 
                      type="number"
                      required 
                      value={this.state.emailSmtp.port}
                      onChange={this.handleEmailItemSmtp} 
                      data-type="string" 
                  />],
                  ["SMTP User", 
                  <input 
                      className="uic-input inpWith25vw" 
                      id="user" 
                      placeholder="User" 
                      autoComplete="off" 
                      type="text"
                      required 
                      defaultValue={this.state.emailSmtp.user}
                      onChange={this.handleEmailItemSmtp} 
                      data-type="string" 
                  />],
                  ["SMTP Password", 
                  <input 
                      className="uic-input inpWith25vw" 
                      id="password" 
                      placeholder="Password" 
                      autoComplete="off" 
                      type="password"
                      required 
                      defaultValue={this.state.emailSmtp.password}
                      onChange={this.handleEmailItemSmtp} 
                      data-type="string" 
                  />],
                  ["From Email", 
                  <input 
                      className="uic-input inpWith25vw" 
                      id="defaultFromEmail" 
                      placeholder="From Email" 
                      autoComplete="off" 
                      type="text"
                      required 
                      defaultValue={this.state.emailSmtp.defaultFromEmail}
                      onChange={this.handleEmailItemSmtp} 
                      data-type="string" 
                  />],
                  ["From Name", 
                  <input 
                      className="uic-input inpWith25vw" 
                      id="defaultFromName" 
                      placeholder="From Name" 
                      autoComplete="off" 
                      type="text"
                      required 
                      defaultValue={this.state.emailSmtp.defaultFromName}
                      onChange={this.handleEmailItemSmtp} 
                      data-type="string" 
                  />],
                  ["Email Subject", 
                  <input 
                      className="uic-input inpWith25vw" 
                      id="defaultSubject" 
                      placeholder="Email Subject" 
                      autoComplete="off" 
                      type="text"
                      required 
                      defaultValue={this.state.emailSmtp.defaultSubject}
                      onChange={this.handleEmailItemSmtp} 
                      data-type="string" 
                  />]
              
              ]}
              /> 
          
            </>
          )
      
    }

    renderMailJetSettings() {

      if(this.state.emailServerType !== "mailjet"){
        return null;
      }

      return (
            <>

            <Table
              params 
              alignLabelsLeft
              yPad={10}
              xPad={20}
              rows={[
              ["Api Key", 
              <input 
                  className="uic-input inpWith25vw" 
                  id="apikey" 
                  placeholder="Key" 
                  autoComplete="off" 
                  type="text"
                  required 
                  defaultValue={this.state.emailMailJet.apikey}
                  onChange={this.handleEmailItemMailJet} 
                  data-type="string" 
              />],
              ["Api Secret", 
              <input 
                  className="uic-input inpWith25vw" 
                  id="apisecret" 
                  placeholder="Secret" 
                  autoComplete="off" 
                  type="text"
                  required 
                  defaultValue={this.state.emailMailJet.apisecret}
                  onChange={this.handleEmailItemMailJet} 
                  data-type="string" 
              />],

              ["From Email", 
              <input 
                  className="uic-input inpWith25vw" 
                  id="defaultFromEmail" 
                  placeholder="From Email" 
                  autoComplete="off" 
                  type="text"
                  required 
                  defaultValue={this.state.emailMailJet.defaultFromEmail}
                  onChange={this.handleEmailItemMailJet} 
                  data-type="string" 
              />],
              ["From Name", 
              <input 
                  className="uic-input inpWith25vw" 
                  id="defaultFromName" 
                  placeholder="From Name" 
                  autoComplete="off" 
                  type="text"
                  required 
                  defaultValue={this.state.emailMailJet.defaultFromName}
                  onChange={this.handleEmailItemMailJet} 
                  data-type="string" 
              />],
              ["Email Subject", 
              <input 
                  className="uic-input inpWith25vw" 
                  id="defaultSubject" 
                  placeholder="Email Subject" 
                  autoComplete="off" 
                  type="text"
                  required 
                  defaultValue={this.state.emailMailJet.defaultSubject}
                  onChange={this.handleEmailItemMailJet} 
                  data-type="string" 
              />]
          
          ]}
          /> 
   
          </>
        )
  }

  renderMailGunSettings() {

    if(this.state.emailServerType !== "mailgun"){
      return null;
    }

    return (
          <>

          <Table
            params 
            alignLabelsLeft
            yPad={10}
            xPad={20}
            rows={[
            ["Api Key", 
            <input 
                className="uic-input inpWith25vw" 
                id="apikey" 
                placeholder="Api Key" 
                autoComplete="off" 
                type="text"
                required 
                defaultValue={this.state.emailMailGun.apikey}
                onChange={this.handleEmailItemMailGun} 
                data-type="string" 
            />],
            ["Api Domain", 
            <input 
                className="uic-input inpWith25vw" 
                id="apidomain" 
                placeholder="Api Domain" 
                autoComplete="off" 
                type="text"
                required 
                defaultValue={this.state.emailMailGun.apidomain}
                onChange={this.handleEmailItemMailGun} 
                data-type="string" 
            />],

            ["From Email", 
            <input 
                className="uic-input inpWith25vw" 
                id="defaultFromEmail" 
                placeholder="From Email" 
                autoComplete="off" 
                type="text"
                required 
                defaultValue={this.state.emailMailGun.defaultFromEmail}
                onChange={this.handleEmailItemMailGun} 
                data-type="string" 
            />],
            ["From Name", 
            <input 
                className="uic-input inpWith25vw" 
                id="defaultFromName" 
                placeholder="From Name" 
                autoComplete="off" 
                type="text"
                required 
                defaultValue={this.state.emailMailGun.defaultFromName}
                onChange={this.handleEmailItemMailGun} 
                data-type="string" 
            />],
            ["Email Subject", 
            <input 
                className="uic-input inpWith25vw" 
                id="defaultSubject" 
                placeholder="Email Subject" 
                autoComplete="off" 
                type="text"
                required 
                defaultValue={this.state.emailMailGun.defaultSubject}
                onChange={this.handleEmailItemMailGun} 
                data-type="string" 
            />]
        
        ]}
        /> 
        </>
      )
  
  } 

  renderMailSendGridSettings() {

    if(this.state.emailServerType !== "sendgrid"){
      return null;
    }

    return (
          <>

          <Table
            params 
            alignLabelsLeft
            yPad={10}
            xPad={20}
            rows={[
            ["Api Key", 
            <input 
                className="uic-input inpWith25vw" 
                id="apikey" 
                placeholder="Api Key" 
                autoComplete="off" 
                type="text"
                required 
                defaultValue={this.state.emailSendGrid.apikey}
                onChange={this.handleEmailItemSendGrid} 
                data-type="string" 
            />],
            
            ["From Email", 
            <input 
                className="uic-input inpWith25vw" 
                id="defaultFromEmail" 
                placeholder="From Email" 
                autoComplete="off" 
                type="text"
                required 
                defaultValue={this.state.emailSendGrid.defaultFromEmail}
                onChange={this.handleEmailItemSendGrid} 
                data-type="string" 
            />],
            ["From Name", 
            <input 
                className="uic-input inpWith25vw" 
                id="defaultFromName" 
                placeholder="From Name" 
                autoComplete="off" 
                type="text"
                required 
                defaultValue={this.state.emailSendGrid.defaultFromName}
                onChange={this.handleEmailItemSendGrid} 
                data-type="string" 
            />],
            ["Email Subject", 
            <input 
                className="uic-input inpWith25vw" 
                id="defaultSubject" 
                placeholder="Email Subject" 
                autoComplete="off" 
                type="text"
                required 
                defaultValue={this.state.emailSendGrid.defaultSubject}
                onChange={this.handleEmailItemSendGrid} 
                data-type="string" 
            />]
        
        ]}
        /> 
 
        </>
      )
}


  sendTestEmail = (emailAdr) => {

    const data = {
      emailServerType: this.state.emailServerType,
      emailSmtp: this.state.emailSmtp,
      emailMailJet: this.state.emailMailJet,
      emailMailGun : this.state.emailMailGun,
      emailSendGrid: this.state.emailSendGrid,
      misc : this.state.miscConfig,
      sendToEmail: emailAdr
    }

    pushNotification("Sending email to: " + emailAdr , {
      variant: "success",
    });

    axios.post("./api/sendTestEmail", data, this.tokenHeader).then((res) => {
       // console.log("sendTestEmail OK", res);

        pushNotification("Email has been successfully sent to: " + emailAdr , {
          variant: "success",
        });

    }).catch((err) => {

      if(err.response) {
        console.log("err.response:", err.response)
       // 400 = BadRequest
       if (err.response.status === 400) {
          if(err.response.data && err.response.data.message) {
            pushNotification(err.response.data.message);
          }
        } else {
          this.catchUnAuthorized(err,"sendTestEmail");
        }
      }
    });
  }

    /* end email */ 

    renderInfoPublicUrl = () => {
      const markdown = `
      Public Url: This is the public adress for your Beacon Server (loaded from the Docker config)` 
      
      return (<InfoTooltip children={markdown} style={{marginLeft:"5px"}} />)
    }

    renderInfoPublicUrlOverride = () => {
      const markdown = `
      Public Url Override: This will override the "Public Url". Please consider changing the Docker config first!` 
      
      return (<InfoTooltip children={markdown} style={{marginLeft:"5px"}} />)
    }

  
    renderStunWarning() {

      if(!this.state.iceConfigItems) {
        return;
      }

      if(!this.state.isIceLoaded) {
        return;
      }

      const stunItems = this.state.iceConfigItems.filter(f => f.iceType===1); 
      
      if(stunItems) {
        
        if(stunItems.length === 0) {  
          return(
            <Panel style={{backgroundColor:"#df6320", height: "40px"}}>
            <div style={{position:"relative", marginTop:"-10px"}}>
              <WarningIcon fontSize="large" style={{color: "#dedede"}} /><span style={{color: "#ffffff", top:"-10px", marginLeft:"5px", position:"relative", fontWeight:"500"}} >  
              STUN server is not configured — you need one STUN servers, please configure!</span>
            </div>
          </Panel>
          )
        }
      }
    }

    renderTurnWarning() {

      if(!this.state.iceConfigItems) {
        return;
      }

      if(!this.state.isIceLoaded) {
        return;
      }

      const turnItems = this.state.iceConfigItems.filter(f => f.iceType===2); 
      
      if(turnItems) {
        
        if(turnItems.length === 0) {  
          return(
            <Panel style={{backgroundColor:"#df6320", height: "40px"}}>
            <div style={{position:"relative", marginTop:"-10px"}}>
              <WarningIcon fontSize="large" style={{color: "#dedede"}} /><span style={{color: "#ffffff", top:"-10px", marginLeft:"5px", position:"relative", fontWeight:"500"}} >  
              TURN servers are not configured — please consider adding TURN servers</span>
            </div>
          </Panel>
          )
        }
      }
    }

    renderEmailWarning() {

      if(!this.state.firstsConfig) {
        return;
      }

      if(!this.state.isIceLoaded) {
        return;
      }

      if(this.state.firstsConfig && this.state.firstsConfig.isFirstMailConfig) {
        return(
          <Panel style={{backgroundColor:"#df6320", height: "40px"}}>
          <div style={{position:"relative", marginTop:"-10px"}}>
            <WarningIcon fontSize="large" style={{color: "#dedede"}} /><span style={{color: "#ffffff", top:"-10px", marginLeft:"5px", position:"relative", fontWeight:"500"}} >  
            Email settings are not configured — please select service and configure it. </span>
          </div>
        </Panel>
        )
      }
    }

    renderMiscWarning() {

     
      if(!this.state.isIceLoaded) {
        return;
      }

      let showUrlWarning = true;

      if(this.state.miscConfig.publicUrl) { showUrlWarning = false; }
      if(this.state.miscConfig.publicUrlOverride) { showUrlWarning = false; }

      if(showUrlWarning) {
        return(
          <Panel style={{backgroundColor:"#df6320", height: "40px"}}>
          <div style={{position:"relative", marginTop:"-10px"}}>
            <WarningIcon fontSize="large" style={{color: "#dedede"}} /><span style={{color: "#ffffff", top:"-10px", marginLeft:"5px", position:"relative", fontWeight:"500"}} >  
            "Public Url" or "Public Url Override" is not configured — please check configuration.</span>
          </div>
        </Panel>
        )
      }

      if(this.state.miscConfig.publicUrl && !this.state.miscConfig.publicUrl.startsWith("https://") && !this.state.miscConfig.publicUrlOverride) {
        return(
          <Panel style={{backgroundColor:"#990000", height: "40px"}}>
          <div style={{position:"relative", marginTop:"-10px"}}>
            <WarningIcon fontSize="large" style={{color: "#dedede"}} /><span style={{color: "#ffffff", top:"-10px", marginLeft:"5px", position:"relative", fontWeight:"500"}} >  
            Public Url must start with HTTPS — please check configuration.</span>
          </div>
        </Panel>
        )
      }

      if(this.state.miscConfig.publicUrlOverride && !this.state.miscConfig.publicUrlOverride.startsWith("https://")) {
        return(
          <Panel style={{backgroundColor:"#990000", height: "40px"}}>
          <div style={{position:"relative", marginTop:"-10px"}}>
            <WarningIcon fontSize="large" style={{color: "#dedede"}} /><span style={{color: "#ffffff", top:"-10px", marginLeft:"5px", position:"relative", fontWeight:"500"}} >  
            Public Url Override must start with HTTPS — please check configuration.</span>
          </div>
        </Panel>
        )
      }
    }

    render() { 

        if(!this.state.isLoaded) {
          return("");
        }

        if(this.state.isUnAuthorized){
          return(<h1>Un-Authorized</h1>);
        }

        const stunItems = this.state.iceConfigItems.filter(f => f.iceType===1); 
        const turnItems = this.state.iceConfigItems.filter(f => f.iceType===2); 


        return ( 
            <React.Fragment>
                {this.renderModalEdit()}
            
                <Panel title={"Ice Setup"}>

                    {this.renderStunWarning()}

                    <Panel title={<><span style={{fontSize:"15px"}} >STUN Server</span> <AddBoxIcon visibility={!this.state.allowNew ? "hidden" : "showing"} onClick={() => this.handleNewIceItem(1)} style={{position:"relative", top:"5px", color:"#48ACF4", cursor:"pointer"}}/></>}>
                    
                        <Table 
                        params 
                        striped
                        alignLabelsLeft="left"
                        headers={["URL","User","Pass", "", ""]}
                        rows={
                            stunItems.map((cfg) => 
                                [cfg.iceUrl,cfg.user, (cfg.pass) ? '*****' : '', 
                                <IconButton onClick={() => this.handleEditBtn(cfg.iceServerItemId)} icon={EditIcon} />,
                                <IconButton
                                icon={DeleteIcon}
                                tooltip="Remove STUN server"
                                onClick={async (event) => {
                                  event.preventDefault();
                                  if (
                                    await confirm(
                                      <>
                                        Sure you want to remove<br /><b>{cfg.iceUrl}</b>?
                                      </>,
                                      { variant: "warning" }
                                    )
                                  ) {
                                    this.handleDeleteIceItem(cfg.iceServerItemId);
                                  }
                                }}
                              />
                              ]
                            )
                        }
                        />
                    </Panel>     
                    <br/>  

                    {this.renderTurnWarning()}

                    <Panel  title={<><span style={{fontSize:"15px"}} >TURN Servers</span> <AddBoxIcon visibility={!this.state.allowNew ? "hidden" : "showing"} onClick={() => this.handleNewIceItem(2)} style={{position:"relative", top:"5px", color:"#48ACF4", cursor:"pointer"}}/></>}>
                        
                        
                        <Table 
                        params 
                        striped
                        alignLabelsLeft="left"
                        headers={["URL","User","Pass", "", ""]}
                        rows={
                            turnItems.map((cfg) => 
                                [cfg.iceUrl,cfg.user, (cfg.pass) ? '*****' : '', 
                                <IconButton onClick={() => this.handleEditBtn(cfg.iceServerItemId)} icon={EditIcon} />,
                                <IconButton onClick={() => this.handleMoveUpBtn(cfg.iceServerItemId, cfg.sortOrder)} icon={ArrowDropUp} /> ,
                                <IconButton onClick={() => this.handleMoveDnBtn(cfg.iceServerItemId, cfg.sortOrder)} icon={ArrowDropDown}  /> ,
                                <IconButton
                                icon={DeleteIcon}
                                tooltip="Remove TURN server"
                                onClick={async (event) => {
                                  event.preventDefault();
                                  if (
                                    await confirm(
                                      <>
                                        Sure you want to remove<br /><b>{cfg.iceUrl}</b>?
                                      </>,
                                      { variant: "warning" }
                                    )
                                  ) {
                                    this.handleDeleteIceItem(cfg.iceServerItemId);
                                  }
                                }}
                              />
                              ],
                            )
                        }
                        />
                    
                    </Panel>        

                   <div style={{marginTop:"8px", fontSize:"11px", color:"#c0c0c0"}}>Changing Ice Setup will eject All connected clients</div>   
                </Panel> 

                <br/><br/>   

                {!this.state.hasAppSettings && (
                  <MuiAlert severity="warning">Please configure 'App Setup'</MuiAlert>
                )}

               
                <Panel title={"App Setup"} >
                <Grid container spacing={3} >       

                    <Grid item xs={12} sm={6}>
                        
                        <Panel title={<><span style={{fontSize:"15px"}} >Email Server</span></>}>
                        {this.renderEmailWarning()}
                            <Table
                                alignLabelsLeft
                                yPad={10}
                                xPad={20}
                                rows={[
                                ["Mode", 
                                <><Select 
                                value={this.state.emailServerType}
                                onChange={id => this.handleEmailServerType(id)}
                            >
                                {this.emailServerTypes.map(
                                    ({id, label}) => (<option value={id} key={id}>{label}</option>)
                                )}
                                </Select>
                                
                                {this.renderSendTestEmail()}

                                </>],
                               
                            ]}
                            />
                            

                            {this.renderSmtpSettings()}
                            {this.renderMailJetSettings()}
                            {this.renderMailGunSettings()}
                            {this.renderMailSendGridSettings()}

                        </Panel>
                    </Grid>        
                             
                    <Grid item xs={12} sm={6}>
                          <Panel title={<><span style={{fontSize:"15px"}} >Misc</span></>}>
                            {this.renderMiscWarning()}
                            <Table
                                alignLabelsLeft
                                yPad={10}
                                xPad={20}
                                rows={[
                                ["Public Url", 
                                <>
                                <input 
                                    className="uic-input inpWith20vw" 
                                    id="publicUrl" 
                                    autoComplete="off" 
                                    type="text"
                                    placeholder="Public Url"
                                    value={this.state.miscConfig.publicUrl}
                                    disabled={true}
                                />
                                {this.renderInfoPublicUrl()} </>],
                                ["Public Url Override", 
                                <><input 
                                    className="uic-input inpWith20vw" 
                                    id="publicUrlOverride" 
                                    autoComplete="off" 
                                    type="text"
                                    placeholder="Public Url Override"
                                    maxLength={128}
                                    value={this.state.miscConfig.publicUrlOverride}
                                    onChange={this.handleMiscConfig}
                                    data-type="string" 
                                />{this.renderInfoPublicUrlOverride()}</>],
                                ["Invite Password length", 
                                <>
                                <input 
                                    className="uic-input inpWith100" 
                                    id="invitePasswordLength" 
                                    autoComplete="off" 
                                    type="number"
                                    required 
                                    defaultValue={this.state.miscConfig.invitePasswordLength}
                                    onChange={this.handleMiscConfig}
                                    data-type="int" 
                                    min={0}
                                    max={64}
                                /></>
                               ],
                               ["Default Invite duration", 
                               <>
                               <input 
                                   className="uic-input inpWith100" 
                                   id="inviteDuration" 
                                   autoComplete="off" 
                                   type="number"
                                   required 
                                   defaultValue={this.state.miscConfig.inviteDuration}
                                   onChange={this.handleMiscConfig}
                                   data-type="int" 
                                   min={1}
                                   max={100}
                               />
                               <Select 
                                  value={this.state.miscConfig.inviteDurationUnit}
                                  onChange={val => this.handleMiscDurationUnit(val)}
                                  id="inviteDurationUnit" 
                                >
                                <option value="minutes" key="durs_1">minutes</option>
                                <option value="hours" key="durs_2">hours</option>
                                <option value="months" key="durs_5">months</option>
                                <option value="years" key="durs_6">years</option>
                                </Select>
                               </>
                              ],
                              ["Invite Title", 
                                <input 
                                    className="uic-input inpWith20vw" 
                                    id="inviteEmailTitle" 
                                    autoComplete="off" 
                                    type="text"
                                    placeholder="Invite Title"
                                    value={this.state.miscConfig.inviteEmailTitle}
                                    onChange={this.handleMiscConfig}
                                    data-type="string" 
                                />
                              ]
                            ]}
                            />

                        </Panel>
                    </Grid>
                </Grid>


                <Divider />
                <Button color="blue" style={{marginTop:"20px",marginBottom:"10px"}} onClick={() =>{ this.saveAppSettings(true) }}><SaveIcon />Save App Setup</Button>  

              
                </Panel>    

                <br/><br/>  
                <br/><br/>       

            </React.Fragment>


         );
    }

   

}



 
export default Settings;