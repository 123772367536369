import React from "react";
import {
    BrowserRouter,
    Switch,
    NavLink,
    Route,
    Redirect,
    withRouter,
    matchPath,
} from "react-router-dom";
import ChevronIcon from "@material-ui/icons/ChevronLeftRounded";
import MenuIcon from '@material-ui/icons/Menu';

import { closeAllModals } from "./UICoreRoot";

import "./framework.css";
import telosLogo from "./ta-logo.svg";

const getPadding = () => (window.innerWidth <= 1352 ? 8 : 16);

class Routing extends React.Component {
    state = {
        sitePadding: getPadding(),
        caughtError: null,
        navCollapsed: localStorage.getItem("navCollapsed") === "true",
        mobileNavOpen: false,
        //hideSideMenu: this.props.hideSideMenu,
    };

    static getDerivedStateFromError(error) {
        console.error(error);
        return { caughtError: error.toString() };
    }

    componentDidMount() {
        // hide navbar if no routes
        if (!this.props.routes.length) {
            document.documentElement.classList.add("uic-noNav");
        }

        window.addEventListener("resize", this.onResize);

        const baseUri = document.getElementsByTagName('base')[0].baseURI; // get the url that is used (without nav pages) ex: https://www.mydomain.com/subdir1/subdir2/
        const baseUrl = baseUri.replace(window.location.origin, ""); // remove the orgin Url. ex: https://www.mydomain.com
        // baseURL then returns: /subdir1/subdir2/

        // console.log("window.location.origin:", window.location.origin);
        console.log("baseUri:", baseUri);
        console.log("baseUrl:", baseUrl);

    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.onResize);
    }

    onResize = () => {
        const sitePadding = getPadding();
        this.setState(state =>
            state.sitePadding !== sitePadding ? { sitePadding } : null
        );

        if (this.state.mobileNavOpen && window.innerWidth > 767.98) {
            this.setState({ mobileNavOpen: false });
        }
    };

    render() {
        const {
            routes = [],
            home,
            // deviceInfo,
            navLogo,
            productLogo = navLogo,
            productTitle,
            topBarChildren,
            allRoutesLoaded = true,
            isLoggedIn, // TODO: rename `unprotectAll` 
            children,
        } = this.props;


        const {
            sitePadding,
            caughtError,
            navCollapsed,
            mobileNavOpen,
        } = this.state;

        let navWidth = navCollapsed && !mobileNavOpen
            ? { "--NAV-WIDTH": "40px" } : {};

        const globalProps = { sitePadding };

        const _routes = window._telosIdRoutes
            ? [...routes, ...window._telosIdRoutes]
            : routes;

        const filteredRoutes = _routes.filter(
            ({ isProtected }) => !isProtected || isLoggedIn
        );

        const homeRoute = (
            <Route
                exact={home.exact !== false}
                path="/"
                render={routeProps =>
                    !home ? null : (
                        <home.component {...routeProps} {...home.props} {...globalProps} />
                    )
                }
            />
        );

        const AccountMenu = window._telosIdAccountMenu;

        const hideSideMenu = ({ ...home.props }).hideSideMenu;
        const hideTopMenu = ({ ...home.props }).hideTopMenu;


        if (hideSideMenu) {
            navWidth = { "--NAV-WIDTH": "0px" };
        }


        // yes, this needs to be here also... If I use a state in "componentDidMount" it's not fast enough and the render here happens before state is set...

        const baseUri = document.getElementsByTagName('base')[0].baseURI; // get the url that is used (without nav pages) ex: https://www.mydomain.com/subdir1/subdir2/
        const baseUrl = baseUri.replace(window.location.origin, ""); // remove the orgin Url. ex: https://www.mydomain.com

        return (
            <BrowserRouter basename={baseUrl}>
                <HandleRouteChange
                    onRouteChange={() => {
                        window.scrollTo(0, 0);

                        closeAllModals();

                        if (caughtError) {
                            this.setState({ caughtError: null });
                        }

                        if (mobileNavOpen) {
                            this.setState({ mobileNavOpen: false })
                        }

                        window.dispatchEvent(new CustomEvent("routeChange", { detail: {} }));
                    }}
                    render={({ location, history }) => (
                        <>
                            {/*----------- Top Bar: -----------*/}

                            {!hideTopMenu && (
                                <>
                                    <header className="topBar" role="banner">
                                        <MenuIcon className="mobileNavBtn" onClick={() => {
                                            this.setState({ mobileNavOpen: !mobileNavOpen })
                                        }} />
                                        <NavLink exact to={"/"} className="productLogo">
                                            {productLogo && <img src={productLogo} role="presentation" alt="" />}
                                            {productTitle && <span className="productTitle">{productTitle}</span>}
                                        </NavLink>

                                        {topBarChildren}

                                        <img src={telosLogo} className="telosLogo" role="presentation" alt="" />

                                        {AccountMenu && <AccountMenu isLoggedIn={isLoggedIn} history={history} />}

                                    </header>
                                </>
                            )}


                            {/*----------- Nav Sidebar: -----------*/}

                            {!hideSideMenu && (

                                <nav
                                    className={`nav ${navCollapsed && !mobileNavOpen ? "navCollapsed" : ""} ${mobileNavOpen ? "mobileNavOpen" : ""}`}
                                    style={navWidth}
                                    role="navigation"
                                >
                                    <div className="nav-innerScroll">
                                        <ul className="nav-list">
                                            {isLoggedIn && !allRoutesLoaded && (
                                                <div className="nav-link">&nbsp;</div>
                                            )}

                                            {filteredRoutes.map(
                                                ({
                                                    path,
                                                    name,
                                                    icon: Icon,
                                                    subRoutes,
                                                    spacer,
                                                    className = "",
                                                    navItemProps,
                                                }, i) => {
                                                    const match = matchPath(location.pathname, { path });

                                                    const mappedSubRoutes = subRoutes && (
                                                        <div className="nav-sub-container">
                                                            <ul className="nav-sub-list">
                                                                {navCollapsed && (
                                                                    <li
                                                                        key={path + "__top"}
                                                                        {...navItemProps}
                                                                        className={`nav-sub-item flyOut-top-item ${className}`}
                                                                    >
                                                                        <NavLink
                                                                            to={path}
                                                                            className="nav-subLink"
                                                                        >
                                                                            {name}
                                                                        </NavLink>
                                                                    </li>
                                                                )}

                                                                {subRoutes.map(({
                                                                    path: subPath,
                                                                    name: subName,
                                                                    className = "",
                                                                    navItemProps,
                                                                }) => (
                                                                    <li
                                                                        key={path + (subPath || subName)}
                                                                        {...navItemProps}
                                                                        className={`nav-sub-item ${className}`}
                                                                    >
                                                                        <NavLink
                                                                            to={path + (subPath || subName)}
                                                                            className="nav-subLink"
                                                                        >
                                                                            {subName}
                                                                        </NavLink>
                                                                    </li>
                                                                ))}
                                                            </ul>
                                                        </div>
                                                    );

                                                    return spacer ? (<hr key={i} />) :
                                                        (
                                                            <li
                                                                key={path}
                                                                {...navItemProps}
                                                                className={`nav-item ${match ? "active" : ""} ${className}`}
                                                            >
                                                                <NavLink
                                                                    to={path}
                                                                    className={`nav-link ${subRoutes ? "nav-parentLink" : ""}`}
                                                                    activeClassName={
                                                                        !subRoutes || location.pathname === path
                                                                            ? "active"
                                                                            : "subIsActive"
                                                                    }
                                                                >
                                                                    {Icon && <Icon />}
                                                                    <span className="nav-item-name">{name}</span>
                                                                </NavLink>
                                                                {mappedSubRoutes}
                                                            </li>
                                                        );
                                                }
                                            )}
                                        </ul>
                                        {children}
                                        <div className="nav-collapseBtn" role="presentation" onClick={() => {
                                            this.setState({ navCollapsed: !navCollapsed })
                                            localStorage.setItem("navCollapsed", !navCollapsed);
                                        }}>
                                            <ChevronIcon />
                                        </div>
                                    </div>
                                </nav>

                            )}



                            {/*--------- Main Page Content: ---------*/}

                            <main
                                className={mainClassName(location)}
                                style={navWidth}
                                role="main"
                                aria-live="polite"
                            >
                                {caughtError
                                    ? errorBoundaryMsg
                                    : (
                                        <FragmentSupportingSwitch>
                                            <>
                                                {homeRoute}

                                                {filteredRoutes.map(
                                                    ({
                                                        path,
                                                        exact = true,
                                                        component: Component,
                                                        props = {},
                                                        subRoutes,
                                                        spacer,
                                                    }) =>
                                                        !spacer && path && (
                                                            <React.Fragment key={path}>
                                                                <Route
                                                                    exact={exact}
                                                                    path={path}
                                                                    render={routeProps => (
                                                                        <Component
                                                                            {...routeProps}
                                                                            {...props}
                                                                            {...globalProps}
                                                                        />
                                                                    )}
                                                                />
                                                                {subRoutes &&
                                                                    subRoutes.map(sub => (
                                                                        <Route
                                                                            exact={sub.exact !== false}
                                                                            path={path + sub.path}
                                                                            key={path + sub.path}
                                                                            render={routeProps => (
                                                                                <sub.component
                                                                                    {...routeProps}
                                                                                    {...sub.props}
                                                                                    {...globalProps}
                                                                                />
                                                                            )}
                                                                        />
                                                                    ))}
                                                            </React.Fragment>
                                                        )
                                                )}

                                                {allRoutesLoaded && <Redirect to={{ pathname: "/" }} />}
                                            </>
                                        </FragmentSupportingSwitch>
                                    )}
                            </main>

                            <div
                                className={`mobile-overlay ${mobileNavOpen ? "mobileNavOpen" : ""}`}
                                onClick={() => {
                                    this.setState({ mobileNavOpen: false })
                                }}
                            />
                        </>
                    )}
                />
            </BrowserRouter>
        );
    }
}

class RouteChange extends React.Component {
    componentDidUpdate(prevProps) {
        if (this.props.location.pathname !== prevProps.location.pathname) {
            this.props.onRouteChange();
        }
    }
    render() {
        return this.props.render(this.props);
    }
}
const HandleRouteChange = withRouter(RouteChange);

function FragmentSupportingSwitch({ children }) {
    const flattenedChildren = [];
    flatten(flattenedChildren, children);
    return React.createElement.apply(
        React,
        [Switch, null].concat(flattenedChildren)
    );
}

function flatten(target, children) {
    React.Children.forEach(children, child => {
        if (React.isValidElement(child)) {
            if (child.type === React.Fragment) {
                flatten(target, child.props.children);
            } else {
                target.push(child);
            }
        }
    });
}

const mainClassName = (location) => {
    //const [_, rt = "", subrt = ""] = location.pathname.split("/");
    const [rt = "", subrt = ""] = location.pathname.split("/");
    return `main ${rt} ${subrt ? rt + "-" + subrt : `${rt}-root`}`;
};

const errorBoundaryMsg = (
    <div className="uic-routingCaughtError">
        <h2>Sorry, something went wrong.</h2>
        <p>
            <div>Make sure you are using a supported browser that is up to date.</div>
            <br />
            <b>If the problem persists, please contact our 24/7 tech support:</b>
        </p>
        <a href="https://www.telosalliance.com/support">
            https://www.telosalliance.com/support
        </a>
    </div>
)

export { Routing };